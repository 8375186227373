import { Route, Routes } from '@angular/router';
import { RoutingSegment } from '../../common/entities/routing-segment';
import { LoginGuardService, MemberGuardService, RoleGuardService, RootGuardService } from '../../guard';
import { SplashGuardService } from '../../guard/splash-guard.service';
import { administrationProgramRoutes } from '../../therapy/program-routing.module';

export const routeTemplates: Routes = [
    {
        path: '',
        canActivate: [RootGuardService],
        loadChildren: () => import('../../common/pages/default/default.module').then((m) => m.DefaultPageModule),
    },
    {
        path: 'signalr',
        canActivate: [SplashGuardService],
        loadChildren: () => import('../../measurement/pages/signalr/signalr.module').then((m) => m.SignalrPageModule),
    },
    {
        path: RoutingSegment.LOGIN,
        canActivate: [LoginGuardService],
        loadChildren: () => import('../../common/pages/login/login.module').then((m) => m.LoginPageModule),
    },
    {
        path: RoutingSegment.COMMON,
        canActivate: [SplashGuardService],
        loadChildren: () =>
            import('../../common/common-module-routing.module').then((m) => m.CommonModuleRoutingModule),
    },
    {
        path: RoutingSegment.INFO,
        loadChildren: () => import('../../common/pages/info/info.module').then((m) => m.InfoPageModule),
    },
    {
        path: RoutingSegment.SHOP,
        canActivate: [SplashGuardService],
        loadChildren: () => import('../../shop/shop-routing.module').then((m) => m.ShopRoutingModule),
    },
    {
        path: RoutingSegment.REGISTER,
        canActivate: [SplashGuardService],
        loadChildren: () => import('../../user/user-routing.module').then((m) => m.RegistrationRoutingModule),
    },

    {
        path: RoutingSegment.MEMBER,
        canActivate: [MemberGuardService],
        children: [
            {
                path: RoutingSegment.HOME,
                loadComponent: () => import('../../common/pages/home/home.page').then((c) => c.HomePage),
                canActivate: [RoleGuardService],
            },
            {
                path: `${RoutingSegment.HOME}/${RoutingSegment.MEETINGS}/${RoutingSegment.EXERCISE}/${RoutingSegment.DETAIL}/:id`,
                loadChildren: () =>
                    import(
                        './../../therapy/pages/manual/administration/course-exercise-manual-detail/course-exercise-manual-detail.module'
                    ).then((m) => m.CourseExerciseManualDetailPageModule),
                canActivate: [RoleGuardService],
            },
            {
                path: RoutingSegment.SHOP,
                canActivate: [SplashGuardService],
                loadChildren: () => import('../../shop/shop-routing.module').then((m) => m.ShopRoutingModule),
            },
            {
                path: RoutingSegment.MEETINGS,
                loadChildren: () => import('./../../meeting/meeting-routes.definition').then((c) => c.CourseRoutes),
            },
            {
                path: RoutingSegment.KIOSK,
                loadComponent: () =>
                    import('../../kiosk/pages/home-page/kiosk-home-page.component').then((m) => m.KioskHomePage),
            },
            {
                path: RoutingSegment.JOURNAL,
                loadChildren: () =>
                    import('../../therapy/pages/organizer/organizer.module').then((m) => m.OrganizerPageModule),
            },
            {
                path: RoutingSegment.CHAT,
                loadChildren: () => import('../../chat/chat-routing.module').then((m) => m.ChatRoutingModule),
            },
            {
                path: RoutingSegment.CONSULTATION_MANAGEMENT,
                loadChildren: () =>
                    import('../../consultation/pages/consultation-creation/consultation-creation.module').then(
                        (m) => m.ConsultationCreationModule,
                    ),
            },
            {
                path: RoutingSegment.ORDER_MANAGEMENT,
                loadChildren: () =>
                    import('../../order/pages/order-creation/order-creation.module').then((m) => m.OrderCreationModule),
            },
            {
                path: RoutingSegment.SCREENING_MANAGEMENT,
                loadChildren: () =>
                    import('../../screening/pages/screening-creation/screening-creation.module').then(
                        (m) => m.ScreeningCreationModule,
                    ),
            },
            {
                path: RoutingSegment.QUESTIONNAIRE_MANAGEMENT,
                loadChildren: () =>
                    import('../../questionnaire/pages/questionnaire-management.module').then(
                        (m) => m.QuestionnaireManagementPageModule,
                    ),
            },
            {
                path: RoutingSegment.CAREGIVER_MANAGEMENT,
                children: [
                    {
                        path: RoutingSegment.TRAINING_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/training-routing.module').then(
                                (m) => m.TherapistTrainingRoutingModule,
                            ),
                    },
                ],
            },
            {
                path: RoutingSegment.PATIENT_MANAGEMENT,
                children: [
                    {
                        path: RoutingSegment.CHAT,
                        loadChildren: () => import('../../chat/chat-routing.module').then((m) => m.ChatRoutingModule),
                    },
                    {
                        path: RoutingSegment.HOME,
                        loadComponent: () =>
                            import('../../common/pages/home-patient/home-patient-page.component').then(
                                (m) => m.HomePatientPage,
                            ),
                        canActivate: [RoleGuardService],
                    },
                    {
                        path: RoutingSegment.CONSULTATION_MANAGEMENT,
                        loadChildren: () =>
                            import('../../consultation/pages/consultation-detail/consultation-detail.module').then(
                                (m) => m.ConsultationDetailPageModule,
                            ),
                    },
                    {
                        path: RoutingSegment.ORDER_MANAGEMENT,
                        loadChildren: () =>
                            import('../../order/pages/order-detail/order-detail.module').then(
                                (m) => m.OrderDetailPageModule,
                            ),
                    },
                    {
                        path: RoutingSegment.TRAINING_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/training-routing.module').then((m) => m.PatientTrainingRoutingModule),
                    },
                    {
                        path: RoutingSegment.MEASUREMENT_MANAGEMENT,
                        loadChildren: () =>
                            import('../../measurement/measurement-routing.module').then(
                                (m) => m.UserMeasurementRoutingModule,
                            ),
                    },
                    {
                        path: RoutingSegment.MEDICATION_MANAGEMENT,
                        loadChildren: () =>
                            import('../../medication/medication-routing.definition').then((c) => c.medicationRoutes),
                    },
                    {
                        path: RoutingSegment.MOBILITY,
                        loadChildren: () =>
                            import('../../mobility/mobility-routing.module').then((m) => m.MobilityRoutingModule),
                    },
                    {
                        path: RoutingSegment.LEARNING_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/learning-routing.module').then((m) => m.UserLearningRoutingModule),
                    },
                    {
                        path: RoutingSegment.PROGRAM_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/program-routing.module').then((m) => m.ProgramRoutingModule),
                    },
                ],
            },
            {
                path: RoutingSegment.PATIENT_MANAGEMENT,
                loadChildren: () => import('../../user/patient-routing.module').then((m) => m.PatientRoutingModule),
            },
            {
                path: RoutingSegment.PROFILE,
                loadChildren: () =>
                    import('../../common/pages/profile/profile.module').then((m) => m.ProfilePageModule),
                canActivate: [RoleGuardService],
            },
            {
                path: RoutingSegment.CONCEPT,
                loadChildren: () =>
                    import('../../therapy/manual-routing.module').then((m) => m.AdministrationManualRoutingModule),
            },
            {
                path: RoutingSegment.KNOWLEDGE,
                loadChildren: () =>
                    import('../../knowledge/knowledge-routing.module').then((m) => m.KnowledgeMemberRoutingModule),
            },
            {
                path: RoutingSegment.ADMINISTRATION,
                children: [
                    {
                        path: RoutingSegment.USER,
                        loadChildren: () => import('../../user/user-routing.module').then((m) => m.UserRoutingModule),
                    },
                    {
                        path: RoutingSegment.ORGANIZATION,
                        loadChildren: () =>
                            import('../../user/organization-routing.module').then((m) => m.OrganizationRoutingModule),
                    },
                    {
                        path: RoutingSegment.TRAINING_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/training-routing.module').then(
                                (m) => m.AdministrationTrainingRoutingModule,
                            ),
                    },
                    {
                        path: RoutingSegment.TASK_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/task-routing.module').then((m) => m.AdministrationTaskRoutingModule),
                    },
                    {
                        path: RoutingSegment.PROGRAM_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/program-routing.module').then((c) => c.administrationProgramRoutes),
                    },
                    {
                        path: RoutingSegment.MEASUREMENT_MANAGEMENT,
                        loadChildren: () =>
                            import('../../measurement/measurement-routing.module').then(
                                (m) => m.AdministrationMeasurementRoutingModule,
                            ),
                    },
                    {
                        path: '',
                        loadChildren: () =>
                            import('./../../meeting/meeting-routes.definition').then(
                                (c) => c.CourseAdministrationRoutes,
                            ),
                    },
                    {
                        path: RoutingSegment.LEARNING_MANAGEMENT,
                        loadChildren: () =>
                            import('../../therapy/learning-routing.module').then(
                                (m) => m.AdministrationLearningRoutingModule,
                            ),
                    },
                    {
                        path: RoutingSegment.NOTE_MANAGEMENT,
                        loadChildren: () =>
                            import('../../note/administration/note-administration.module').then(
                                (m) => m.NoteAdministrationRoutingModule,
                            ),
                    },
                    {
                        path: RoutingSegment.ANNOUNCEMENT,
                        loadComponent: () =>
                            import(
                                '../../announcement/pages/administration/announcement-administration-page.component'
                            ).then((m) => m.AnnouncementAdministrationPage),
                    },
                ],
            },
            {
                path: RoutingSegment.VIDEO_CONFERENCE,
                loadChildren: () =>
                    import('../../video-conference/video-conference-routing.module').then(
                        (m) => m.VideoConferenceRoutingModule,
                    ),
            },
        ],
    },
] satisfies Route[];
