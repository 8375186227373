import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FilterSettingItem } from '../../../user/entities/user-preferences/user-preferences';
import { Tag } from '../../entities/tag/tag.entity';
import { CurafidaCommonModule } from '../../../common/curafida-common.module';
import { CurafidaTableComponent } from '../../../table/components/curafida-table/curafida-table.component';
import { ExerciseType } from '../../entities/exerciseSession';
import { ActionEmitter, ActionType, TableConfig, TableUpdateValue } from '../../../table/entities/table';
import { Exercise, ExerciseCatalog } from '../../entities/exercise';
import { LoadingProcess } from '../../../table/components/curafida-table/loading.process';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ExercisesService } from '../../services/exercises';
import { ExerciseContentsService } from '../../services/exercise-contents';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UsersPreferencesService } from '../../../user/services/user-preferences';
import { TagsService } from '../../services/tags';
import { Content, ContentMetaDataType } from '../../entities/content';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { MimeTypeCurafida } from '../../../common/entities/mime.type';
import { MediaContentAdapterComponent } from '../../../table/components/table-adapter/media-content-adapter.component';
import { ContentFormatType } from '../../../common/entities/content-format-type';
import { TagListModalComponent } from '../modal/tag-list-modal/tag-list-modal.component';

export class ExerciseFilterSetting extends FilterSettingItem {
    trainingConfigurationTags: Tag[];
    taskTags: Tag[];
    learningTags: Tag[];
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'exercise-overview',
    templateUrl: './exercise-overview.component.html',
    styleUrls: ['./exercise-overview.component.scss'],
    standalone: true,
    imports: [CurafidaCommonModule, CurafidaTableComponent],
})
export class ExerciseOverviewComponent implements OnInit {
    @Input()
    createExerciseString = 'Übung erstellen';
    @Input()
    isGroup = false;
    @Input()
    hasFilter = true;
    @Input()
    hasRightToModified = false;
    @Input()
    exerciseType: ExerciseType = ExerciseType.TRAINING;
    @Input()
    exerciseListConfig: TableConfig<ExerciseCatalog[]>;
    searchTerm: string;
    filterTags: Tag[] = [];
    isLoading = LoadingProcess.initLoadingProcess();
    readonly exerciseFilterSetting: ExerciseFilterSetting;
    private readonly MAX_DESCRIPTION_LENGTH = 300;
    private limit = 10;
    private offset = 0;
    private sortOrder = SortOrder.ASC;
    private sortBy = SortBy.TITLE;
    private exercisePaginatedResponse: PaginatedResponse<ExerciseCatalog[]>;

    constructor(
        private exercisesService: ExercisesService,
        private exerciseContentsService: ExerciseContentsService,
        private router: Router,
        private modalController: ModalController,
        private usersPreferencesService: UsersPreferencesService,
        private tagsService: TagsService,
    ) {
        this.exerciseFilterSetting =
            this.usersPreferencesService.userPreferences?.filterSettings?.filterSettingItem.find(
                (i) => i.name === this.constructor.name,
            ) as ExerciseFilterSetting;
        if (!this.exerciseFilterSetting) this.exerciseFilterSetting = new ExerciseFilterSetting(this.constructor.name);
    }

    async ngOnInit() {
        await this.initTagsDependingToExerciseType();
        await this.getExerciseList({ offset: 0 }, this.searchTerm);
    }

    organizeContent(table: Content[]) {
        table.sort((a, b) => {
            if (a.jsonData.order < b.jsonData.order) {
                return -1;
            } else if (a.jsonData.order > b.jsonData.order) {
                return 1;
            }
            return 0;
        });
        return table;
    }

    async openExercisePage(exercise?: Exercise) {
        const id = exercise ? exercise.id : 'new';
        if (this.exerciseType === ExerciseType.TRAINING) {
            if (this.router.url.includes(RoutingSegment.CONCEPT)) {
                await this.router.navigate([
                    RoutingSegment.MEMBER,
                    RoutingSegment.CONCEPT,
                    RoutingSegment.TRAINING_MANAGEMENT,
                    RoutingSegment.EXERCISE,
                    RoutingSegment.DETAIL,
                    id,
                ]);
            } else {
                await this.router.navigate([
                    RoutingSegment.MEMBER,
                    RoutingSegment.ADMINISTRATION,
                    RoutingSegment.TRAINING_MANAGEMENT,
                    RoutingSegment.EXERCISE,
                    RoutingSegment.DETAIL,
                    id,
                ]);
            }
        }

        if (this.exerciseType === ExerciseType.LEARNING) {
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.ADMINISTRATION,
                RoutingSegment.LEARNING_MANAGEMENT,
                RoutingSegment.EXERCISE,
                RoutingSegment.DETAIL,
                id,
            ]);
        }
        if (this.exerciseType === ExerciseType.TASK) {
            await this.router.navigate([
                RoutingSegment.MEMBER,
                RoutingSegment.ADMINISTRATION,
                RoutingSegment.TASK_MANAGEMENT,
                RoutingSegment.TASK_TEMPLATES,
                RoutingSegment.DETAIL,
                id,
            ]);
        }
    }

    async getExerciseList(value: TableUpdateValue, searchTerm: string, hasNewPreferencesValue = false) {
        this.isLoading = LoadingProcess.initLoadingProcess();
        if (value.limit) this.limit = value.limit;
        const tagsList = this.filterTags ? this.filterTags.map((tag) => tag.uuid) : [];
        if (hasNewPreferencesValue) this.updateTaskFilterSetting();

        // @ts-ignore
        this.exercisePaginatedResponse = (await this.exercisesService.getExercises(
            value.offset * this.limit,
            this.limit,
            searchTerm,
            this.sortOrder,
            this.sortBy,
            this.exerciseType,
            true,
            false,
            true,
            tagsList,
        )) as PaginatedResponse<ExerciseCatalog[]>;

        this.exercisePaginatedResponse.items = this.exercisePaginatedResponse.items.map((exercise) => {
            if (this.exerciseType === ExerciseType.TASK) {
                if (exercise.contents && exercise.contents.find((c) => c.mimeType === MimeTypeCurafida.MY_MEDAX)) {
                    exercise.type = 'FORM';
                } else if (exercise.metaInfo?.taskType === 'FREE_TEXT') {
                    exercise.type = 'FREE_TEXT';
                } else {
                    exercise.type = 'ASSIGNMENT';
                }
            }

            if (this.exerciseListConfig.itemSettings.find((col) => col.adapter === MediaContentAdapterComponent)) {
                if (exercise.exerciseType === ExerciseType.TRAINING) {
                    if (
                        exercise.contents?.find(
                            (e) => e?.jsonData?.contentMetaDataType === ContentMetaDataType.THUMBNAIL,
                        )
                    ) {
                        const content = exercise.contents.find(
                            (e) => e.jsonData?.contentMetaDataType === ContentMetaDataType.THUMBNAIL,
                        );
                        this.exerciseContentsService
                            .getObjectURLContentFromUrl(content.uuid, ContentFormatType.THUMBNAIL)
                            .then((url) => (exercise.url = url));
                        exercise.mimeType = content.mimeType;
                    }
                } else {
                    this.organizeContent(exercise.contents);
                    if (exercise.contents[0]) {
                        this.exerciseContentsService
                            .getObjectURLContentFromUrl(exercise.contents[0].uuid, ContentFormatType.THUMBNAIL)
                            .then((url) => (exercise.url = url));
                        exercise.mimeType = exercise.contents[0].mimeType;
                    }
                }
            }
            if (exercise.tags) {
                exercise.tagsList = '';
                for (const tag of exercise.tags) {
                    exercise.tagsList = `${exercise.tagsList}${tag.label}, `;
                }
                exercise.tagsList = exercise.tagsList.slice(0, exercise.tagsList.length - 2);
            }
            if (exercise.description.length > this.MAX_DESCRIPTION_LENGTH) {
                exercise.description = exercise.description.slice(0, this.MAX_DESCRIPTION_LENGTH) + '...';
            }
            return exercise;
        });
        this.exerciseListConfig.list = this.exercisePaginatedResponse;
        this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
    }

    async setFilter() {
        const modal = await this.modalController.create({
            component: TagListModalComponent,
            cssClass: 'modal-tag-list-css',
            componentProps: {
                title: 'Filter',
                exerciseTags: this.filterTags,
                exerciseType: this.exerciseType,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data) {
            this.updateTagsDependingToExerciseType(data);
            await this.getExerciseList({ offset: this.offset }, this.searchTerm, true);
        }
    }

    setAction(actionEmitter: ActionEmitter<Exercise>) {
        if (actionEmitter.actionType === ActionType.OPEN_NEW_PAGE) {
            this.openExercisePage(actionEmitter.item);
        }
    }

    private async initTagsDependingToExerciseType() {
        if (this.exerciseType === ExerciseType.TRAINING) {
            this.filterTags = this.exerciseFilterSetting.trainingConfigurationTags = await this.initTagList(
                ExerciseType.TRAINING,
                this.exerciseFilterSetting.trainingConfigurationTags,
            );
        }

        if (this.exerciseType === ExerciseType.LEARNING) {
            this.filterTags = this.exerciseFilterSetting.learningTags = await this.initTagList(
                ExerciseType.LEARNING,
                this.exerciseFilterSetting.learningTags,
            );
        }

        if (this.exerciseType === ExerciseType.TASK) {
            this.filterTags = this.exerciseFilterSetting.taskTags = await this.initTagList(
                ExerciseType.TASK,
                this.exerciseFilterSetting.taskTags,
            );
        }
    }

    private async updateTagsDependingToExerciseType(tags: Tag[]) {
        if (this.exerciseType === ExerciseType.TRAINING) {
            this.filterTags = this.exerciseFilterSetting.trainingConfigurationTags = tags;
        }

        if (this.exerciseType === ExerciseType.LEARNING) {
            this.filterTags = this.exerciseFilterSetting.learningTags = tags;
        }

        if (this.exerciseType === ExerciseType.TASK) this.filterTags = this.exerciseFilterSetting.taskTags = tags;
    }

    private async initTagList(exerciseType: ExerciseType, tags: Tag[]): Promise<Tag[]> {
        if (!tags) {
            return [];
        } else {
            let isModified = false;
            const tagCategories = await this.tagsService.getTagCategories(exerciseType);
            let tagFromServer = [];
            for (const tags of tagCategories) {
                tagFromServer = tagFromServer.concat(tags.tags);
            }
            for (const savedTag of tags) {
                if (!tagFromServer.find((i) => i.uuid === savedTag.uuid)) {
                    tags = tags.filter((i) => i.uuid !== savedTag.uuid);
                    isModified = true;
                }
            }
            if (isModified) {
                this.updateTaskFilterSetting();
            }
            return tags;
        }
    }

    private async updateTaskFilterSetting() {
        await this.usersPreferencesService.updateFilterSettingItem(this.exerciseFilterSetting);
    }
}
