import { Component, OnInit } from '@angular/core';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../../table/entities/table';
import { ModalController } from '@ionic/angular';
import { ExerciseCommentModalComponent } from '../exercise-comment/exercise-comment-modal.component';
import { FeedbackCommentsService } from '../../../services/feedback-comments';
import { FeedbackComment, FeedbackCommentDto } from '../../../entities/feedback/feedback-comment';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { ModalAlertService } from '../../../../common/services/modal';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { LoadingProcess, LoadingStates } from '../../../../table/components/curafida-table/loading.process';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';

@Component({
    selector: 'lib-exercise-comments',
    templateUrl: './feedback-comments.component.html',
    styleUrls: ['./feedback-comments.component.scss'],
})
export class FeedbackCommentsComponent implements OnInit {
    protected readonly log: Logger;
    isLoading: LoadingStates = LoadingProcess.initLoadingProcess();
    customerComments: PaginatedResponse<FeedbackComment[]>;
    constructor(
        private modalController: ModalController,
        private feedBackCommentService: FeedbackCommentsService,
        private toastService: ToastService,
        private modalAlertService: ModalAlertService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    ngOnInit(): void {
        this.initCustomerComments({ limit: 10, offset: 0 });
    }

    initFeedbackCommentTable(offset = 0, limit = 10) {
        const config = new TableConfig<FeedbackComment[]>();
        config.emptyListLabel = 'TRAINING.COMMENT.ANY_ITEM';
        config.isOpenDetailEnable = false;
        config.itemSettings = [
            {
                prop: 'text',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 1,
            },
            {
                prop: 'flag',
                header: 'Farbe',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '44%',
                columnPosition: 2,
            },
            {
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.UPDATE,
                icon: 'create',
                width: '8%',
                columnPosition: 3,
            },
            {
                prop: '',
                header: '',
                adapter: ButtonItemAdapterComponent,
                type: ItemType.ADAPTER,
                actionType: ActionType.DELETE,
                icon: 'trash',
                color: 'danger',
                width: '8%',
                columnPosition: 4,
            },
        ];
        config.list = this.customerComments;
        return config;
    }

    initCustomerComments(value: TableUpdateValue) {
        this.feedBackCommentService.getFeedbackComments(value.offset * value.limit, value.limit).subscribe({
            next: (customerComments) => {
                this.customerComments = customerComments;
                this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
            },
            error: (err) => {
                this.customerComments = PaginatedResponse.init([]);
                this.log.error(err);
                this.isLoading = LoadingProcess.finishedWithErrorLoadingProcess();
            },
        });
    }

    async updateComment(comment?: FeedbackCommentDto) {
        const modal = await this.modalController.create({
            component: ExerciseCommentModalComponent,
            cssClass: 'modal-create-exercise-comment-css',
            componentProps: {
                comment,
            },
        });
        await modal.present();
        await modal.onDidDismiss();
        this.initCustomerComments({ offset: 0, limit: 10 });
        this.isLoading = LoadingProcess.finishedSuccessfullyLoadingProcess();
    }

    async setActionOnItem(actionEmitter: ActionEmitter<FeedbackComment>) {
        if (actionEmitter.actionType === ActionType.UPDATE) {
            await this.updateComment(actionEmitter.item);
        }
        if (actionEmitter.actionType === ActionType.DELETE) {
            await this.removeExerciseComment(actionEmitter.item);
        }
    }

    async removeExerciseComment(feedbackComment: FeedbackComment) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = `${feedbackComment.text} löschen`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = `Die Daten werden unwiderruflich gelöscht.
        Wenn Sie den Übungskommentar „${feedbackComment.text}“ löschen möchten, klicken Sie auf „Löschen“.`;

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Löschen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                await this.feedBackCommentService.deleteFeedbackComments(feedbackComment.uuid);
                await this.toastService.showToast('Der Kommentar wurde gelöscht', IonicColor.success);
            } catch (err) {
                this.log.error('Error in FeedbackCommentsComponent', err);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            } finally {
                this.initCustomerComments({ offset: 0, limit: 10 });
            }
        }
    }
}
