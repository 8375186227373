import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FileUploader } from 'ng2-file-upload';
import { Content, ContentDto } from '../../../entities/content';
import { DateFormat, ItemType, TableConfig, TableUpdateValue } from '../../../../table/entities/table';
import { ExerciseContentsService } from '../../../services/exercise-contents';
import { PaginatedResponse } from '../../../../common/entities/paginated-response';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { ModalAlertService } from '../../../../common/services/modal';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { UPLOAD_MIME_TYPE_WHITELIST } from '../../../../common/entities/white-list-mime-type';
import { CheckBoxItemAdapterComponent } from '../../../../table/components/table-adapter/checkbox-item-adapter.component';

@Component({
    selector: 'lib-contents-list',
    templateUrl: './contents-list-checkbox.component.html',
    styleUrls: ['./contents-list-checkbox.component.scss'],
})
export class ContentsListCheckboxComponent implements OnInit {
    title = 'Unterlage wählen';
    selectedExercisesContent: PaginatedResponse<Content[]> = new PaginatedResponse<Content[]>();
    contentTableConfiguration: TableConfig<Content[]> = new TableConfig();
    limit = 10;
    searchTerm = '';
    public uploader: FileUploader = new FileUploader({
        url: '',
        allowedMimeType: UPLOAD_MIME_TYPE_WHITELIST,
        // queueLimit: 1,
        maxFileSize: 20 * 1024 * 1024,
    });
    whiteListMimeType = UPLOAD_MIME_TYPE_WHITELIST.join();
    @ViewChild('fileUploader', { static: true }) fileUploader: ElementRef;
    protected readonly log: Logger;

    constructor(
        protected modalCtrl: ModalController,
        protected toastService: ToastService,
        private modalAlertService: ModalAlertService,
        private params: NavParams,
        private exerciseContentService: ExerciseContentsService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.contentTableConfiguration.emptyListLabel = 'CONTENT.ANY';
        this.contentTableConfiguration.isOpenDetailEnable = true;
        this.contentTableConfiguration.itemSettings = [
            {
                id: 'selected',
                prop: 'selected',
                header: '',
                adapter: CheckBoxItemAdapterComponent,
                type: ItemType.ADAPTER,
                width: '8%',
                columnPosition: 0,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Datei',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '50%',
                columnPosition: 1,
            },
            {
                id: 'mimeType',
                prop: 'mimeType',
                header: 'Type',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '20%',
                columnPosition: 2,
            },
            {
                id: 'created_at',
                prop: 'created_at',
                header: 'Erstellungsdatum',
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE,
                type: ItemType.ADAPTER,
                width: '22%',
                columnPosition: 3,
            },
        ];
    }

    ngOnInit() {
        this.initContentList();
    }

    async getContentsList(value: TableUpdateValue) {
        this.limit = value.limit;
        try {
            this.selectedExercisesContent = this.params.get('contents');
            this.contentTableConfiguration.list = await this.exerciseContentService.getExerciseContents(
                value.offset * this.limit,
                this.limit,
                this.searchTerm,
            );
            const localContentList: Content[] = [];
            this.contentTableConfiguration.list.items.map((contentMapped, index) => {
                if (!this.selectedExercisesContent.items.find((content) => content.uuid === contentMapped.uuid)) {
                    contentMapped.selected = false;
                    localContentList.push(contentMapped);
                }
                if (this.contentTableConfiguration.list.total - 1 === index) {
                    this.contentTableConfiguration.list.items = localContentList;

                    this.contentTableConfiguration.list.limit = this.contentTableConfiguration.list.count =
                        localContentList.length;
                }
            });
        } catch (e) {
            this.log.error('Error in getContentsList', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
        }
    }

    async addNewContent() {
        this.uploader.clearQueue();
        this.fileUploader.nativeElement.click();
        this.uploader.onAfterAddingAll = async (files) => {
            for (const file of files) {
                try {
                    const assetInput = new FormData();
                    assetInput.append('file', file.file.rawFile);
                    let content = await this.exerciseContentService.postContentFileUpload(assetInput);
                    const contentDto = new ContentDto();
                    contentDto.description = file.file.name;
                    contentDto.mimeType = content.mimeType;
                    contentDto.byteSize = content.byteSize;
                    content = await this.exerciseContentService.updateExerciseContent(content.uuid, contentDto);
                    content.selected = true;
                    this.contentTableConfiguration.list.items.push(content);
                    this.contentTableConfiguration.list.total =
                        this.contentTableConfiguration.list.count =
                        this.contentTableConfiguration.list.limit +=
                            1;
                } catch (e) {
                    this.log.error('Error in addNewContent', e);
                    await this.toastService.showToast(
                        `Beim Hochladen der Datei ${file.file.name} ist ein Fehler aufgetreten.`,
                        IonicColor.danger,
                    );
                }
            }
            this.fileUploader.nativeElement.value = '';
            this.uploader.clearQueue();
        };
        this.uploader.onWhenAddingFileFailed = async (item, filter) => {
            switch (filter.name) {
                // The size filter first has to be enabled in the FileUploader options
                case 'fileSize':
                    await this.toastService.showToast(
                        `Die Datei ${item.name} ist zu groß. Maximale Dateigröße 20 MB.`,
                        IonicColor.danger,
                    );
                    break;
                case 'mimeType':
                    await this.toastService.showToast(
                        `Das Format der Datei ${item.name} wird nicht unterstützt.`,
                        IonicColor.danger,
                    );
                    break;
                // The queue limit first has to be enabled in the FileUploader options
                case 'queueLimit':
                    await this.toastService.showToast(
                        `Datei ${item.name} wurde nicht hochgeladen, es kann nur eine Datei gleichzeitig hochgeladen werden`,
                        IonicColor.danger,
                    );
                    break;
                default:
                    this.log.error('Error in addNewContent', `Unknown error (filter is ${filter.name})`);
                    await this.toastService.showToast(
                        `Beim Hochladen der Datei ${item.name} ist ein Fehler aufgetreten.`,
                        IonicColor.danger,
                    );
                    break;
            }
        };
    }

    dismissModal(reloadView = false) {
        this.modalCtrl.dismiss({
            reload: reloadView,
        });
    }

    async onActivate(event) {
        await this.selectContentFromList(event);
    }

    async selectContentFromList(content: Content) {
        this.contentTableConfiguration.list.items.map((item) => {
            if (item.uuid === content.uuid) {
                if (item.selected) {
                    const index = this.selectedExercisesContent.items.findIndex(
                        (itemIndex) => itemIndex.uuid === content.uuid,
                    );
                    this.selectedExercisesContent.items.splice(index, 1);
                    this.selectedExercisesContent.total =
                        this.selectedExercisesContent.count =
                        this.selectedExercisesContent.limit -=
                            1;
                } else {
                    this.selectedExercisesContent.items.push(content);
                    this.selectedExercisesContent.total =
                        this.selectedExercisesContent.count =
                        this.selectedExercisesContent.limit +=
                            1;
                }
                item.selected = !item.selected;
            }
        });
    }

    async passContent() {
        await this.modalCtrl.dismiss({
            reload: true,
            contents: this.selectedExercisesContent,
        });
    }

    private async initContentList() {
        if (this.params.get('contents')) {
            this.selectedExercisesContent = this.params.get('contents');
            await this.getContentsList({ offset: 0, limit: this.limit });
        }
    }
}
