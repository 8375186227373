// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conclusion-badge {
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 35px;
  text-align: center;
  top: 16px;
  margin-left: -13px;
  background-color: #ffffff;
  z-index: 100;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./../../libs/therapy/components/training/process/training-conclusion/training-conclusion.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,SAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AACJ","sourcesContent":[".conclusion-badge {\n    color: #fff;\n    width: 30px;\n    height: 30px;\n    line-height: 35px;\n    text-align: center;\n    top: 16px;\n    margin-left: -13px;\n    background-color: #ffffff;\n    z-index: 100;\n    border-radius: 50%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
