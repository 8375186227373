import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ActionItemType, ActionMenuItem } from '../../entities/action-menu.item';

@Component({
    selector: 'lib-curafida-popover-selection',
    templateUrl: './curafida-popover-selection.component.html',
    styleUrls: ['./curafida-popover-selection.component.scss'],
})
export class CurafidaPopoverSelectionComponent {
    ActionMenuItemType = ActionItemType;
    actionItems: ActionMenuItem[] = [];

    constructor(private popoverController: PopoverController) {}

    async sendAction(value: any) {
        await this.popoverController.dismiss(value);
    }

    isType(item: ActionMenuItem, type: ActionItemType) {
        return type === item.type;
    }
}
