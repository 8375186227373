import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import SwiperCore, { Controller, EffectFade, Navigation, Pagination, SwiperOptions } from 'swiper';

import { HttpClient } from '@angular/common/http';
import { ExerciseContentsService } from '../../../services/exercise-contents';
import { Content } from '../../../entities/content';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { TrainingContent, TrainingExercise } from '../../../entities/training/training';
import { ContentFormatType } from '../../../../common/entities/content-format-type';
import { SwiperComponent } from 'swiper/angular';
import { SwitchComponent } from '../../../../common/components/switch/switch.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { StyleService } from '../../../../common/services/style/style.service';
import { Exercise } from '../../../entities/exercise';
import { User, UserRoles } from '../../../../auth/entities/user';
import { CurafidaAuthService } from '../../../../auth/services';

SwiperCore.use([Navigation, Pagination, EffectFade, Controller]);

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'lib-exercise-explanation',
    templateUrl: './exercise-explanation.component.html',
    styleUrls: ['./exercise-explanation.component.scss'],
})
export class ExerciseExplanationComponent implements OnInit, OnChanges {
    @ViewChild('swiper') swiper: SwiperComponent;
    @ViewChild('switchComponent') switchComponent: SwitchComponent;
    @ViewChild('swiperSlide', { read: ElementRef }) swiperSlide: ElementRef;
    title = 'Anleitung';
    isBeginning = true;
    isEnd = false;
    currentIndex = 0;

    @Input()
    areContentVisible = false;
    @Input()
    trainingExercise: TrainingExercise;
    @Input()
    exercise: Exercise;
    @Input()
    hideFeedbackButton = false;
    @Input()
    hasTitle = false;
    loggedInUser: User;

    isLeftArrowDisabled = true;
    isRightArrowDisabled = false;
    height: number;

    @Output()
    feedback = new EventEmitter();
    @Output()
    contentVisibility = new EventEmitter<boolean>();
    Content = Content;
    initSlideFinished = false;
    config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 0,
        centerInsufficientSlides: true,
        effect: 'slide',
        speed: 1000,
        setWrapperSize: true,
        autoHeight: false,
        height: 300,
    };

    isMobile = false;
    UserRoles = UserRoles;
    private inFullScreenMode = false;

    constructor(
        protected platform: Platform,
        private exerciseContentsService: ExerciseContentsService,
        protected http: HttpClient,
        private so: ScreenOrientation,
        private styleService: StyleService,
        private authService: CurafidaAuthService,
    ) {
        platform.ready().then(() => {
            this.height = platform.height();
            this.isMobile = this.styleService.isMobile$;
        });
        this.loggedInUser = this.authService.getSession().user;
    }

    @HostListener('document:fullscreenchange', ['$event'])
    @HostListener('document:webkitfullscreenchange', ['$event'])
    @HostListener('document:mozfullscreenchange', ['$event'])
    @HostListener('document:MSFullscreenChange', ['$event'])
    async onFullScreenChange() {
        if (this.inFullScreenMode) {
            await this.so.lock(this.so.ORIENTATIONS.PORTRAIT_PRIMARY);
        } else {
            this.so.unlock();
        }
        this.inFullScreenMode = !this.inFullScreenMode;
    }

    async ngOnInit() {
        this.initSlideFinished = false;
        await this.initializeSlides();
    }

    async initializeSlides() {
        let i = 0;
        for (const content of this.trainingExercise?.contents) {
            if (Content.isAudioMimeType(content.mimeType) || Content.isVideoMimeType(content.mimeType)) {
                content.url = await this.exerciseContentsService.getTempDownloadUrl(content.uuid);
            } else if (Content.isPDFMimeType(content.mimeType)) {
                let tempBlob = null;
                let tempRetFileData = null;
                //Fetching Data File
                this.exerciseContentsService.getObservableContent(content).subscribe((retFileData: any) => {
                    tempRetFileData = retFileData;
                    tempBlob = new Blob([retFileData], { type: content.mimeType });
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        content.pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
                    };
                    fileReader.readAsArrayBuffer(tempBlob);
                });
            } else {
                content.url = await this.exerciseContentsService.getObjectURLContentFromUrl(
                    content.uuid,
                    ContentFormatType.MEDIUM_SIZE,
                );
            }
            if (i === this.trainingExercise.contents.length - 1) {
                this.initSlideFinished = true;
                this.isBeginning = true;
                this.isEnd = false;
                this.setSwitch(0);
                if (this.swiper) {
                    this.swiper.swiperRef.slideTo(0);
                    this.swiper.swiperRef.allowSlidePrev = true;
                    this.swiper.swiperRef.on('slideChange', () => {
                        this.slideChanged();
                    });
                }
            }
            i++;
        }
    }

    async ngOnChanges() {
        this.currentIndex = 0;
        await this.initializeSlides();
    }

    openFeedback() {
        this.feedback.emit();
    }

    async nextSlide() {
        this.swiper.swiperRef.slideNext(1000);
    }

    async previousSlide() {
        this.swiper.swiperRef.slidePrev(1000);
    }

    async slideChanged() {
        this.currentIndex = this.swiper.swiperRef.activeIndex;
        this.setSwitch(this.currentIndex);
    }

    setSwitch(index: number) {
        if (index === 0 && index === this.trainingExercise.contents.length - 1) {
            this.isLeftArrowDisabled = true;
            this.isRightArrowDisabled = true;
            this.isBeginning = true;
            this.isEnd = true;
        } else if (index === 0) {
            this.isLeftArrowDisabled = true;
            this.isRightArrowDisabled = false;
            this.isBeginning = true;
            this.isEnd = false;
        } else if (index === this.trainingExercise.contents.length - 1) {
            this.isEnd = true;
            this.isBeginning = false;
            this.isLeftArrowDisabled = false;
            this.isRightArrowDisabled = true;
        } else {
            this.isBeginning = false;
            this.isEnd = false;
            this.isLeftArrowDisabled = false;
            this.isRightArrowDisabled = false;
        }
    }

    async downloadFile(content: TrainingContent) {
        await this.exerciseContentsService.openAttachmentObjectURLinNewWindow(content, ContentFormatType.MEDIUM_SIZE);
    }

    async downloadPDFFile(content: TrainingContent) {
        await this.exerciseContentsService.downloadObjectURLinNewWindow(content);
    }

    showContent() {
        this.contentVisibility.emit(!this.areContentVisible);
    }
}
