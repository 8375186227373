import {
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    KeyValueDiffer,
    KeyValueDiffers,
    Output,
    Type,
} from '@angular/core';
import {
    ActionEmitter,
    ActionType,
    ItemAdapterComponent,
    ItemType,
    TableConfig,
    TableItem,
    TableUpdateValue,
} from '../../entities/table';
import { PaginatedResponse, SortOrder } from '../../../common/entities/paginated-response';
import { ItemReorderEventDetail } from '@ionic/core';
import { Content } from '../../../therapy/entities/content';
import { Subject, Subscription } from 'rxjs';
import { IonicModule } from '@ionic/angular';
import { StyleService } from '../../../common/services/style/style.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PipeTableLib } from '../../pipe/pipes-table-lib.module';
import { TranslationModule } from '../../../translation/translation.module';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { CurafidaColumnDefinitionComponent } from '../column-definition/curafida-column-definition.component';
import { CurafidaTranslatePipe } from '../../../translation/pluralization-translate.pipe';
import { LoadingStates } from './loading.process';
import { LoadingComponent } from '../../../common/components/loading/loading.component';
import { debounceTime } from 'rxjs/operators';

export type TableLoading = { success: boolean; processing: boolean };

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'curafida-table',
    templateUrl: './curafida-table.component.html',
    styleUrls: ['./curafida-table.component.scss'],
    standalone: true,
    imports: [
        PipeTableLib,
        NgClass,
        NgForOf,
        NgIf,
        NgStyle,
        IonicModule,
        CurafidaColumnDefinitionComponent,
        LoadingComponent,
        TranslationModule,
    ],
    providers: [CurafidaTranslatePipe],
})
export class CurafidaTableComponent<T> implements DoCheck {
    selected = [];
    paginationList: number[] = [];
    startPaginationToShowList: number[] = [];
    endPaginationToShowList: number[] = [];
    Content = Content;
    isMobile = false;
    platformSubscription: Subscription;
    // output option to open the item
    @Output()
    openDetail = new EventEmitter();
    // output option when a checkbox is clicked
    @Output()
    checkboxAction = new EventEmitter();
    @Output()
    updateTable = new EventEmitter<TableUpdateValue>();
    @Output()
    setActionOnItem = new EventEmitter<ActionEmitter<T>>();
    @Output()
    updateContentOfTable = new EventEmitter<PaginatedResponse<T[]>>();
    @Output()
    isReorderUpdate = new EventEmitter<boolean>();
    @Input()
    mobileAdapter: Type<ItemAdapterComponent>;
    ItemType = ItemType;
    SortOrder = SortOrder;
    mobileList: PaginatedResponse<T[]> = new PaginatedResponse<T[]>();
    notes: TableItem;
    // Table Configuration
    ActionType = ActionType;

    private readonly itemActionDebounce$ = new Subject<ActionEmitter<T>>();
    private differ: KeyValueDiffer<string, any>;

    constructor(
        private styleService: StyleService,
        private differs: KeyValueDiffers,
    ) {
        this.differ = this.differs.find({}).create();
        this.isMobile = this.styleService.isMobile$;
        this.itemActionDebounce$.pipe(debounceTime(300)).subscribe((value) => this.setActionOnItem.emit(value));
    }

    private _isLoading: LoadingStates;

    get isLoading(): LoadingStates {
        return this._isLoading;
    }

    @Input() set isLoading(value: LoadingStates) {
        this._isLoading = value;
        if (value.success && this.listTableConfig?.list) {
            this.initPagination(this.listTableConfig);
        }
    }

    private _listTableConfig: TableConfig<T[]>;

    get listTableConfig(): TableConfig<T[]> {
        return this._listTableConfig;
    }

    @Input()
    set listTableConfig(value: TableConfig<T[]>) {
        if (!value) return;
        if (this.styleService.isMobile$) {
            this.mobileList = value.list;
        } else {
            this._listTableConfig = value;
            this.initTable();
        }
    }

    organizeDescendingTableItem(table: TableItem[]): TableItem[] {
        if (table) {
            table.sort((a, b) => {
                if (a.columnPosition < b.columnPosition) {
                    return -1;
                } else if (a.columnPosition > b.columnPosition) {
                    return 1;
                }
                // a must be equal to b
                return 0;
            });
        }
        return table;
    }

    openDetailPage(indexElement: number): void {
        if (this.listTableConfig.isOpenDetailEnable) {
            this.openDetail.emit(this.listTableConfig.list.items[indexElement]);
        }
    }

    updateList(offset: number, limit: number): void {
        this.updateTable.emit({ offset: offset, limit: limit });
    }

    emitActionItem(event, element, actionType: ActionType, itemSetting: TableItem): void {
        if (event) event.stopPropagation();
        if (actionType === ActionType.OPEN_NEW_PAGE || !actionType) {
            if (this.listTableConfig.isOpenDetailEnable) this.openDetail.emit(element);
        }
        if (actionType === ActionType.DELETE && element.hideDeleteButton) return;
        if (!this.isMobile && element[itemSetting.disabledProp]) return;
        if (itemSetting.disabled) return;
        this.itemActionDebounce$.next(new ActionEmitter<T>(actionType, element));
    }

    emitActionSelection(value: ActionType, element: T, itemSetting?: TableItem): void {
        if (!this.isMobile) if (element[itemSetting?.disabledProp]) return;
        this.emitActionItem(null, element, value, itemSetting);
    }

    initPagination(value: TableConfig<T[]>): void {
        this.paginationList = [];
        const tablePaginationLength = value.list?.total / value.list?.limit ? value.list?.total / value.list?.limit : 0;
        this.paginationList = new Array(parseInt(tablePaginationLength.toString()) + 1).fill(null).map((_, i) => i);

        this.startPaginationToShowList = [...this.paginationList];
        this.startPaginationToShowList = this.startPaginationToShowList.splice(0, 5);
        this.endPaginationToShowList = [...this.paginationList];
        this.endPaginationToShowList = this.endPaginationToShowList.splice(this.endPaginationToShowList.length - 5, 5);
    }

    previousPage(): void {
        this.updateTable.emit({
            offset: this.listTableConfig.list.offset / this.listTableConfig.list.limit - 1,
            limit: this.listTableConfig.list.limit,
        });
    }

    nextPage(): void {
        this.updateTable.emit({
            offset: this.listTableConfig.list.offset / this.listTableConfig.list.limit + 1,
            limit: this.listTableConfig.list.limit,
        });
    }

    openTablePage(offset: number): void {
        this.updateTable.emit({ offset, limit: this.listTableConfig.list.limit });
    }

    doReorder(ev: CustomEvent<ItemReorderEventDetail>): void {
        this.listTableConfig.list.items = ev.detail.complete(this.listTableConfig.list.items);
        let index = 0;
        for (const item of this.listTableConfig.list.items) {
            // @ts-ignore
            item.order = index;
            index++;
        }
        this.isReorderUpdate.emit(true);
        this.updateContentOfTable.emit(this.listTableConfig.list);
    }

    changeSortOrder(itemSetting: TableItem): void {
        if (itemSetting.sortBy) {
            for (const column of this.listTableConfig.itemSettings) {
                if (column.header !== itemSetting.header) column.sortOrder = SortOrder.NONE;
            }
            switch (itemSetting.sortOrder) {
                case SortOrder.NONE:
                    itemSetting.sortOrder = SortOrder.ASC;
                    break;
                case SortOrder.ASC:
                    itemSetting.sortOrder = SortOrder.DESC;
                    break;
                case SortOrder.DESC:
                    itemSetting.sortOrder = SortOrder.NONE;
                    break;
                default:
                    itemSetting.sortOrder = SortOrder.ASC;
                    break;
            }
            const sortOrder = itemSetting.sortOrder !== SortOrder.NONE ? itemSetting.sortOrder : null;
            this.updateTable.emit({ offset: 0, sortBy: itemSetting.sortBy, sortOrder, tableColumnId: itemSetting.id });
        }
    }

    async loadData(event): Promise<void> {
        event.target.complete();
        if (this.mobileList.offset + this.mobileList.limit <= this.listTableConfig.list.total) {
            this.updateList(this.mobileList.offset / this.listTableConfig.list.limit + 1, this.mobileList.limit);
        }
    }

    async ngDoCheck(): Promise<void> {
        if (!this.listTableConfig) {
            return;
        }
        if (this.isMobile) {
            const change = this.differ.diff(this.listTableConfig.list.items);
            if (change) {
                if (this.listTableConfig.list.offset === 0) {
                    this.mobileList = this.listTableConfig.list;
                } else {
                    this.mobileList.offset = this.listTableConfig.list.offset;
                }
                change.forEachItem((item) => {
                    if (
                        this.mobileList &&
                        this.mobileList.count !== 0 &&
                        !this.mobileList.items.includes(item.currentValue)
                    ) {
                        this.mobileList.items.push(item.currentValue);
                        this.mobileList.count++;
                    }
                });
            }
        }
    }

    private initTable(): void {
        if (this.isLoading === undefined) {
            throw new Error(
                '[CurafidaTableComponent init error]: isLoading.processing or isLoading.success is required',
            );
        }
        if (this.listTableConfig === undefined) {
            throw new Error('[CurafidaTableComponent init error]: listTableConfig is required');
        }
        if (this.listTableConfig.emptyListLabel === undefined) {
            throw new Error('[CurafidaTableComponent init error]: emptyListLabel is required');
        }
        this.listTableConfig.itemSettings = this.organizeDescendingTableItem(this.listTableConfig.itemSettings);
        this.initPagination(this.listTableConfig);
    }
}
