export enum RoutingSegment {
    ADMINISTRATION = 'administration',
    APPOINTMENT_MANAGEMENT = 'appointment',
    APPOINTMENT_PLAN = 'appointment_plan',
    ARTICLE = 'article',
    AUTO = 'auto',
    CAREGIVER_MANAGEMENT = 'caregiver',
    CHAT = 'chat',
    CHATBOT = 'chatbot',
    COMMON = 'common',
    CONCEPT = 'concept',
    CONCLUSION = 'conclusion',
    CONFORMITY = 'conformity',
    CONSULTATION_MANAGEMENT = 'consultation',
    CONTACT = 'contact',
    CONTROL = 'control',
    MEETINGS = 'meetings',
    COURSES = 'courses',
    DASHBOARD = 'dashboard',
    DEFAULT = 'default',
    DETAIL = 'detail',
    DEVICES = 'devices',
    DOCUMENTS = 'documents',
    ECG = 'ecg',
    EDIT = 'edit',
    EVALUATION = 'EVALUATION',
    EXAMINATION = 'examination',
    EXERCISE = 'exercises',
    EXERCISE_SERIES_TEMPLATES = 'exercise-series-templates',
    EXERCISE_SESSIONS = 'exercise-sessions',
    EXPERT_MODE = 'expert-mode-measurement',
    FEEDBACK = 'feedback',
    FEEDBACK_SUMMARY = 'feedback-summary',
    FIRST_STEPS = 'first-step',
    GROUP = 'group',
    HELP = 'help',
    HOME = 'home',
    IMPRINT = 'imprint',
    INFO = 'info',
    JOURNAL = 'journal',
    KIOSK = 'kiosk',
    KNOWLEDGE = 'knowledge',
    LEARNING_MANAGEMENT = 'learning-management',
    LEARNING_PLAN_TEMPLATES = 'learning-plan-templates',
    LIST = 'list',
    LOGIN = 'login',
    MAIN = 'main',
    MANUAL = 'manual',
    MEASUREMENT = 'measurement',
    MEASUREMENT_MANAGEMENT = 'measurement-management',
    MEASUREMENTS = 'measurements',
    MEDICATION_MANAGEMENT = 'medication-management',
    MEMBER = 'member',
    MOBILITY = 'mobility',
    NEED = 'NEED',
    NORM_VALUES = 'norm-values',
    NOTE_MANAGEMENT = 'note-management',
    ONBOARDING = 'ONBOARDING',
    ORDER_MANAGEMENT = 'order',
    ORGANIZATION = 'organization',
    PARAMETER = 'parameter',
    PATIENT_LIST = 'patient-list',
    PATIENT_MANAGEMENT = 'patient',
    PREPARATION = 'PREPARATION',
    PRIVACY = 'privacy',
    PRODUCT_EVALUATION = 'PRODUCT_EVALUATION',
    PRODUCT_EVALUATION_QUERY = 'product-evaluation-query',
    PRODUCT_LAUNCH = 'PRODUCT_LAUNCH',
    PRODUCT_SELECTION = 'PRODUCT_SELECTION',
    PROFILE = 'profile',
    PROGRAM_MANAGEMENT = 'program-management',
    PROGRAM_TEMPLATES = 'program-templates',
    PROGRESSION_TEMPLATES = 'progression-templates',
    PROGRESSION = 'progression',
    PUBLIC = 'public',
    QUESTIONNAIRE = 'questionnaire',
    QUESTIONNAIRE_MANAGEMENT = 'questionnaire-management',
    REGISTER = 'register',
    SCENARIO = 'scenario',
    SCREENING_MANAGEMENT = 'screening',
    SETTINGS = 'settings',
    SHOP = 'shop',
    STANDARD_PRIVACY = 'standard-privacy',
    STEPS = 'steps',
    STRATEGY = 'STRATEGY',
    SUCCESSFUL = 'successful',
    SUPERVISOR_MANAGEMENT = 'supervisor',
    TASK_MANAGEMENT = 'task-management',
    TASK_PLAN_TEMPLATES = 'task-plan-templates',
    TASK_TEMPLATES = 'task-templates',
    TASKS = 'tasks',
    TECHNOLOGIES = 'technologies',
    TECHNOLOGY_DETAILS = 'technology-details',
    TERMS_AND_CONDITIONS = 'terms-and-conditions',
    THERAPY = 'therapies',
    THERAPY_TEMPLATE = 'therapy-template',
    TRAINING_MANAGEMENT = 'training',
    TRAINING_TEMPLATE = 'training-template',
    USER = 'user-management',
    USER_AGREEMENT = 'user-agreement',
    USER_MANAGEMENT = 'user-management/user-management', // This was implemented exclusively for Toolbar header button highlighting
    VERSION = 'version-info',
    VIDEO_CALL = 'video-call',
    VIDEO_CONFERENCE = 'video-conference',
    ANNOUNCEMENT = 'announcement',
    TEMPLATE = 'template',
}
