import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CurafidaSegmentItem } from '../../../../common/entities/curafida-segment.item';
import {
    ActionEmitter,
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../../table/entities/table';
import { PaginatedResponse, SortBy, SortOrder } from '../../../../common/entities/paginated-response';
import { RoutingSegment } from '../../../../common/entities/routing-segment';
import { Router } from '@angular/router';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { TherapySessionsService } from '../../../services/therapy-sessions/therapy-sessions.service';
import { FrontendTherapySession, TherapySessionState } from '../../../entities/therapy-session/therapy-session';
import { UsersService } from '../../../../user/services/user';
import { User, UserRoles } from '../../../../auth/entities/user';
import { FeedBack } from '../../../entities/feedback';
import { TrainingResultTableComponent } from '../training-result-table/training-result-table.component';
import { ModalController } from '@ionic/angular';
import { SegmentType } from '../../../../common/entities/segment.type';
import { UsersTherapySessionsService } from '../../../services/users-therapy-sessions/users-therapy-sessions.service';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { FlagFeedbackAdapterComponent } from '../../table-adapter/flag-feedback-adapter.component';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { ActionButton, ActionItemType } from '../../../../table/entities/action-menu.item';
import { CurafidaAuthService } from '../../../../auth/services';

@Component({
    selector: 'lib-training-overview',
    templateUrl: './training-overview.component.html',
    styleUrls: ['./training-overview.component.scss'],
})
export class TrainingOverviewComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;

    @Output()
    activeTrainingCount$ = new EventEmitter<number>();

    limit = 10;
    trainingTableConfig: TableConfig<FrontendTherapySession[]> = new TableConfig<FrontendTherapySession[]>();

    segmentListTraining: CurafidaSegmentItem<TherapySessionState>[] = [];
    displayedTrainingType = TherapySessionState.LOCKED;
    protected readonly log: Logger;
    private usersList: PaginatedResponse<User[]>;
    private offset = 0;
    private loggedInUser: User;

    constructor(
        private router: Router,
        private therapySessionsService: TherapySessionsService,
        private usersService: UsersService,
        private toastService: ToastService,
        private modalCtrl: ModalController,
        private usersTherapySessionService: UsersTherapySessionsService,
        private loggingService: LoggingService,
        private authService: CurafidaAuthService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.initTrainingTable();
    }

    async ngOnInit() {
        this.loggedInUser = this.authService.getSession().user;
        this.usersList = await this.usersService.getUsers({ offset: 0, limit: 1000, roles: [UserRoles.PATIENT] });
        this.initTabs();
        await this.setTrainingSegmentType(this.displayedTrainingType);
    }

    initTrainingTable() {
        this.trainingTableConfig.emptyListLabel = 'ANY_THERAPY_SESSION';
        this.trainingTableConfig.isOpenDetailEnable = true;
        this.trainingTableConfig.itemSettings = [
            {
                id: 'dueDate',
                prop: 'created_at',
                header: 'Einheit vom',
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE_AND_HOUR_2_LINES,
                type: ItemType.ADAPTER,
                width: '15%',
                columnPosition: 1,
                sortOrderMobile: 1,
                showColNameOnMobile: true,
            },
            {
                id: 'patientName',
                prop: 'patientName',
                header: 'Patient',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '20%',
                columnPosition: 2,
                sortOrderMobile: 2,
                showColNameOnMobile: true,
            },
            {
                id: 'therapyName',
                prop: 'therapyName',
                header: 'Trainingsplan',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '32%',
                columnPosition: 3,
                sortOrderMobile: 0,
                isMobileBold: true,
            },
            {
                id: 'lockedLabel',
                prop: 'lockedLabel',
                header: 'Zustand',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '15%',
                columnPosition: 4,
                sortOrderMobile: 3,
                showColNameOnMobile: true,
            },
            {
                id: 'flags',
                prop: 'flags',
                header: 'Flaggen',
                type: ItemType.ADAPTER,
                adapter: FlagFeedbackAdapterComponent,
                width: '10%',
                columnPosition: 5,
                sortOrderMobile: 5,
                showColNameOnMobile: true,
            },
            {
                prop: '',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                actionType: ActionType.POPOVER,
                fill: 'clear',
                icon: 'ellipsis-vertical',
                id: 'action_open',
                width: '8%',
                columnPosition: 6,
                isMobileButton: true,
            },
        ];
    }

    initTabs() {
        this.segmentListTraining = [
            new CurafidaSegmentItem({ name: 'Gesperrt', value: TherapySessionState.LOCKED }),
            new CurafidaSegmentItem({ name: 'Gestartet', value: TherapySessionState.RUNNING }),
            new CurafidaSegmentItem({ name: 'Abgeschlossen', value: TherapySessionState.COMPLETED }),
            new CurafidaSegmentItem({ name: 'Unvollständig', value: TherapySessionState.INCOMPLETE }),
        ];
    }

    async setTrainingSegmentType(event: TherapySessionState) {
        this.displayedTrainingType = event;
        this.limit = 10;
        await this.getTrainingPatientList({ offset: 0, limit: this.limit });
    }

    async getTrainingPatientList(value: TableUpdateValue, sortBy = SortBy.DELAYED_TIME, sortOrder = SortOrder.ASC) {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        this.limit = value.limit;
        this.offset = value.offset;
        let isTherapyLocked;
        let onlyNewestPerTherapy;
        if (this.displayedTrainingType === TherapySessionState.LOCKED) {
            isTherapyLocked = true;
            onlyNewestPerTherapy = true;
        }

        try {
            const therapySessionState =
                this.displayedTrainingType === TherapySessionState.LOCKED ? null : this.displayedTrainingType;
            this.trainingTableConfig.list = (await this.therapySessionsService.getTherapySessions(
                value.offset * this.limit,
                this.limit,
                sortOrder,
                therapySessionState,
                null,
                null,
                null,
                isTherapyLocked,
                onlyNewestPerTherapy,
                new Date().toISOString(),
            )) as PaginatedResponse<FrontendTherapySession[]>;
            if (isTherapyLocked) this.activeTrainingCount$.emit(this.trainingTableConfig.list.total);
            for (const therapySession of this.trainingTableConfig.list.items) {
                therapySession.therapyName = therapySession.therapy.title;
                therapySession.lockedLabel = therapySession.therapy.isLocked ? 'Gesperrt' : 'Freigegeben';
                const user = this.usersList.items.find((u) => u.username === therapySession.username);
                therapySession.patientName = `${user?.lastname}, ${user?.firstname}`;
                therapySession.flags = [];
                if (therapySession.exerciseSessionsOfUser) {
                    for (const exerciseSessionOfUser of therapySession.exerciseSessionsOfUser) {
                        if (exerciseSessionOfUser.feedback) {
                            therapySession.flags.push(
                                (exerciseSessionOfUser.feedback as FeedBack)?.painFlag.toString(),
                            );
                            therapySession.flags.push(
                                (exerciseSessionOfUser.feedback as FeedBack)?.pulseFlag.toString(),
                            );
                            therapySession.flags.push(
                                (exerciseSessionOfUser.feedback as FeedBack)?.commentFlag.toString(),
                            );
                            therapySession.flags.push(
                                (exerciseSessionOfUser.feedback as FeedBack)?.borgFlag.toString(),
                            );
                        }
                    }
                }
                therapySession.actions = [];
                therapySession.actions.push(
                    new ActionButton(ActionItemType.BUTTON, 'Patientenakte öffnen', ActionType.OPEN_NEW_PAGE),
                );
                therapySession.actions.push(
                    new ActionButton(ActionItemType.BUTTON, 'Einheit anzeigen', ActionType.AUDITED),
                );
                if (
                    this.displayedTrainingType === TherapySessionState.RUNNING &&
                    this.loggedInUser.roles.includes(UserRoles.manage_therapy)
                ) {
                    therapySession.actions.push(
                        new ActionButton(ActionItemType.BUTTON, 'Einheit schließen', ActionType.REMOVE),
                    );
                }
            }
            this.isLoadingSuccess = true;
            this.isLoading = false;
        } catch (e) {
            this.log.error('Error in getTrainingPatientList', e);
            this.isLoadingSuccess = false;
            this.isLoading = false;
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.trainingTableConfig.list = new PaginatedResponse<FrontendTherapySession[]>();
        }
    }

    async openDetailPage(username: string) {
        await this.router.navigate(
            [RoutingSegment.MEMBER, RoutingSegment.PATIENT_MANAGEMENT, RoutingSegment.DETAIL, username],
            { queryParams: { segment: SegmentType.TRAINING } },
        );
    }

    async openTrainingPlanDetailPage(actionEmitter: ActionEmitter<FrontendTherapySession>) {
        if (actionEmitter.actionType === ActionType.OPEN_NEW_PAGE) {
            await this.openDetailPage(actionEmitter.item.username);
        }
        if (actionEmitter.actionType === ActionType.AUDITED) {
            const modal = await this.modalCtrl.create({
                component: TrainingResultTableComponent,
                backdropDismiss: false,
                cssClass: 'full-width-modal-css',
                componentProps: {
                    therapySession: actionEmitter.item,
                    isModal: true,
                },
            });
            await modal.present();
        }
        if (actionEmitter.actionType === ActionType.REMOVE) {
            try {
                await this.usersTherapySessionService.setTherapySessionStateToIncomplete(
                    actionEmitter.item.username,
                    actionEmitter.item.uuid,
                );
                await this.getTrainingPatientList({ limit: this.limit, offset: this.offset });
                await this.toastService.showToast(ToastService.changeSavedMessage, IonicColor.success);
            } catch (e) {
                console.error('error: ', e);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }
}
