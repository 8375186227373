import { AppConfiguration } from './app-config-interface';
import { RoutingSegment } from '../../common/entities/routing-segment';
import { PageName } from './page.name';
import { CurafidaFeatureEnum } from './base-page-config';
import { FeatureConfigs, UserRolesType } from '../../common/entities/curafida-frontend-configuration';
import { UserRoles } from '../../auth/entities/user';
import { PageConfig } from './page-config';

export class AppTemplateConfig implements AppConfiguration {
    appDisplayName = 'Curafida';
    defaultUrl = '/default';
    appVersion = '';

    features: FeatureConfigs;

    generalPageConfigs: PageConfig[] = [
        {
            title: 'Rechtliches',
            pageId: `${PageName.COMMON}_${PageName.MAIN}`,
            categories: [RoutingSegment.ADMINISTRATION],
            role: [],
            configuration: {
                menuVisibility: true,
            },
            childPageConfigs: [
                {
                    title: 'Datenschutz',
                    url: `${RoutingSegment.COMMON}/${RoutingSegment.PRIVACY}`,
                    categories: [RoutingSegment.PRIVACY],
                    pageId: PageName.PRIVACY,
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Nutzungsvereinbarung ',
                    url: '/common/user-agreement',
                    categories: [RoutingSegment.USER_AGREEMENT],
                    pageId: PageName.USER_AGREEMENT,
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Impressum',
                    url: '/common/imprint',
                    categories: [RoutingSegment.IMPRINT],
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Version-Information',
                    url: '/common/version-info',
                    categories: [RoutingSegment.IMPRINT],
                    role: [],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'DECLARATION_OF_CONFORMITY',
                    url: `/${RoutingSegment.COMMON}/${RoutingSegment.CONFORMITY}`,
                    categories: [RoutingSegment.CONFORMITY],
                    pageId: PageName.CONFORMITY,
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'TERMS_AND_CONDITIONS.SHORT',
                    url: `/${RoutingSegment.COMMON}/${RoutingSegment.TERMS_AND_CONDITIONS}`,
                    categories: [RoutingSegment.CONFORMITY],
                    module: CurafidaFeatureEnum.shop,
                    pageId: PageName.CONFORMITY,
                    role: [],
                    configuration: {
                        menuVisibility: true,
                        showPageTitle: true,
                    },
                },
            ],
        },
        {
            title: 'Kontakt',
            url: '/common/contact',
            categories: [RoutingSegment.CONTACT],
            pageId: PageName.CONTACT,
            role: [],
            text: `Bei Fragen zum Projekt können Sie sich gerne mit einer E-Mail an <a href='mailto:support@ztm.de' target='_blank'>support@ztm.de</a> wenden.`,
            configuration: {
                menuVisibility: true,
                showPageTitle: true,
            },
        },
    ];
    isUsingMyMedax = false;
    rootUrls = [
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            role: 'ANALYST' as UserRolesType,
        },
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            role: 'CAREGIVER' as UserRolesType,
        },
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            role: 'CATALOG_MANAGER' as UserRolesType,
        },
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.HOME}`,
            role: 'PATIENT' as UserRolesType,
        },
        {
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            role: 'SUPERVISOR' as UserRolesType,
        },
        {
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.USER}/${RoutingSegment.LIST}`,
            role: 'USER_MANAGER' as UserRolesType,
        },
        {
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.KNOWLEDGE}/${RoutingSegment.MAIN}`,
            role: 'KNOWLEDGE_MANAGER' as UserRolesType,
        },
    ];

    pageConfigs: PageConfig[] = [
        {
            title: 'Übersicht',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.HOME}`,
            pageId: PageName.HOME,
            role: [
                UserRoles.ANALYST,
                UserRoles.CAREGIVER,
                UserRoles.CATALOG_MANAGER,
                UserRoles.SUPERVISOR,
                UserRoles.USER_MANAGER,
            ],
            categories: [RoutingSegment.HOME, RoutingSegment.THERAPY],
            module: CurafidaFeatureEnum.common,
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
                showPageTitle: true,
            },
        },
        {
            title: 'Kiosk',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.KIOSK}`,
            pageId: 'KIOSK',
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.HOME],
            module: CurafidaFeatureEnum.common,
            configuration: {},
        },
        {
            title: 'Übersicht',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.HOME}`,
            pageId: `${PageName.PATIENT}_${PageName.HOME}`,
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.HOME],
            module: CurafidaFeatureEnum.common,
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
                showPageTitle: true,
            },
        },
        {
            title: 'Anmelden',
            url: '/login',
            pageId: 'LOGIN',
            role: [],
            categories: [RoutingSegment.LOGIN],
            configuration: {},
        },
        {
            title: 'Registrieren',
            url: `${RoutingSegment.REGISTER}`,
            pageId: 'REGISTER',
            role: [],
            categories: [RoutingSegment.REGISTER],
            configuration: { menuVisibility: true, headerVisibility: true },
            module: CurafidaFeatureEnum.shop,
        },
        {
            title: 'Katalog',
            url: `${RoutingSegment.MEMBER}/${RoutingSegment.SHOP}/${RoutingSegment.MAIN}`,
            pageId: 'SHOP_MAIN',
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.SHOP],
            configuration: { menuVisibility: true, headerVisibility: true },
            module: CurafidaFeatureEnum.shop,
        },
        {
            title: 'Article',
            url: `${RoutingSegment.SHOP}/${RoutingSegment.ARTICLE}`,
            pageId: `${RoutingSegment.MEMBER}/${RoutingSegment.SHOP}_${RoutingSegment.ARTICLE}`,
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.SHOP],
            configuration: { showPageTitle: true },
            module: CurafidaFeatureEnum.shop,
        },
        {
            title: 'Katalog',
            url: `${RoutingSegment.SHOP}/${RoutingSegment.MAIN}`,
            pageId: 'SHOP_MAIN',
            role: [],
            categories: [RoutingSegment.SHOP],
            configuration: { menuVisibility: true, headerVisibility: true },
            module: CurafidaFeatureEnum.shop,
        },
        {
            title: 'Article',
            url: `${RoutingSegment.SHOP}/${RoutingSegment.ARTICLE}`,
            pageId: `${RoutingSegment.SHOP}_${RoutingSegment.ARTICLE}`,
            role: [],
            categories: [RoutingSegment.SHOP],
            configuration: { showPageTitle: true },
            module: CurafidaFeatureEnum.shop,
        },
        {
            title: 'Patienten',
            pageId: `${PageName.PATIENT}_${PageName.LIST}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.LIST}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            role: [UserRoles.read_user, UserRoles.SUPERVISOR, UserRoles.read_patient],
            module: CurafidaFeatureEnum.user,
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
                showPageTitle: true,
            },
        },
        {
            title: 'Chat',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CHAT}/${RoutingSegment.MAIN}`,
            pageId: PageName.CHAT,
            role: [UserRoles.manage_chat],
            categories: [RoutingSegment.CHAT],
            module: CurafidaFeatureEnum.chat,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Chat',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.CHAT}/${RoutingSegment.MAIN}`,
            pageId: PageName.PATIENT_CHAT,
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.CHAT],
            module: CurafidaFeatureEnum.chat,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Patient',
            pageId: PageName.PATIENT_DETAIL,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.user,
            role: [UserRoles.read_user, UserRoles.SUPERVISOR, UserRoles.read_patient],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Kurse detail',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.MEETINGS}/${RoutingSegment.THERAPY}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.HOME],
            module: CurafidaFeatureEnum.course,
            role: [UserRoles.read_therapy],
            pageId: PageName.THERAPY_DETAIL,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Sitzungen',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.APPOINTMENT_MANAGEMENT}/${RoutingSegment.MAIN}`,
            categories: [RoutingSegment.HOME],
            module: CurafidaFeatureEnum.course,
            role: [UserRoles.PATIENT],
            pageId: `${RoutingSegment.APPOINTMENT_MANAGEMENT}_${RoutingSegment.MAIN}`,
            configuration: {
                showPageTitle: false,
                menuVisibility: false,
            },
        },
        {
            title: 'Manual',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONCEPT}/${RoutingSegment.MAIN}`,
            categories: [RoutingSegment.CONCEPT],
            module: CurafidaFeatureEnum.manual,
            role: [UserRoles.manage_therapy, UserRoles.SUPERVISOR],
            pageId: PageName.MANUAL,
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
                showPageTitle: true,
            },
        },
        {
            title: 'Wissen',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.KNOWLEDGE}/${RoutingSegment.MAIN}`,
            pageId: `${RoutingSegment.KNOWLEDGE}/${RoutingSegment.MAIN}`,
            role: [UserRoles.KNOWLEDGE_MANAGER],
            module: CurafidaFeatureEnum.knowledge,
            categories: [RoutingSegment.KNOWLEDGE],
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
                showPageTitle: true,
            },
        },
        {
            title: 'Verwaltung',
            pageId: PageName.ADMINISTRATION,
            categories: [RoutingSegment.ADMINISTRATION, RoutingSegment.USER_MANAGEMENT],
            role: [UserRoles.read_group, UserRoles.manage_user, UserRoles.manage_therapy_template],
            configuration: {
                menuVisibility: true,
                headerVisibility: true,
            },
            childPageConfigs: [
                // USER
                {
                    title: 'Benutzer',
                    pageId: PageName.USER_LIST,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.USER}/${RoutingSegment.LIST}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.user,
                    role: [UserRoles.manage_user],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Betreuer',
                    pageId: PageName.THERAPIST,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.USER_MANAGEMENT}` +
                        `${RoutingSegment.CAREGIVER_MANAGEMENT}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.user,
                    role: [UserRoles.read_user],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Analyst',
                    pageId: PageName.ANALYST,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.USER_MANAGEMENT}` +
                        `${'ANALYST'}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.user,
                    role: [UserRoles.read_user],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Co-Betreuer',
                    pageId: PageName.SUPERVISOR_DETAIL,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.SUPERVISOR_MANAGEMENT}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.user,
                    role: [UserRoles.manage_supervisor],
                    configuration: {
                        showPageTitle: true,
                    },
                },

                // ORGANISATION
                {
                    title: 'Organisationen',
                    pageId: PageName.ORGANIZATION_MANAGEMENT,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.ORGANIZATION}/${RoutingSegment.LIST}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.organisation,
                    role: [UserRoles.read_group],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Organisation',
                    pageId: PageName.ORGANIZATION_DETAIL,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.ORGANIZATION}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.organisation,
                    role: [UserRoles.read_group],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Gruppe',
                    pageId: PageName.GROUP_DETAIL,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.ORGANIZATION}/${RoutingSegment.GROUP}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.organisation,
                    role: [UserRoles.read_group],
                    configuration: {
                        showPageTitle: true,
                    },
                },

                // TRAINING
                {
                    title: 'Training',
                    pageId: `${PageName.TRAINING}_${PageName.LIST}`,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.MAIN}`,
                    categories: [RoutingSegment.TRAINING_MANAGEMENT],
                    module: CurafidaFeatureEnum.training,
                    role: [UserRoles.manage_therapy_template],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Training Detail',
                    pageId: `${PageName.TRAINING}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.PATIENT_MANAGEMENT],
                    module: CurafidaFeatureEnum.training,
                    role: [UserRoles.read_therapy],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Trainingsvorlage',
                    pageId: `${PageName.TRAINING}_${PageName.TEMPLATE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.TRAINING_TEMPLATE}`,
                    categories: [RoutingSegment.TRAINING_MANAGEMENT],
                    module: CurafidaFeatureEnum.training,
                    role: [UserRoles.manage_therapy_template, UserRoles.manage_therapy],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Übung detail',
                    pageId: `${PageName.TRAINING}_${PageName.EXERCISE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.EXERCISE}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.training,
                    role: [UserRoles.manage_exercise, UserRoles.manage_therapy],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Progression',
                    pageId: `${PageName.TRAINING}_${PageName.PROGRESSION}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.PROGRESSION}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.TRAINING_MANAGEMENT],
                    module: CurafidaFeatureEnum.training,
                    role: [UserRoles.manage_therapy],
                    configuration: {
                        showPageTitle: true,
                    },
                },

                // TASK
                {
                    title: 'Aufgaben',
                    pageId: `${PageName.TASK}_${PageName.LIST}`,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.TASK_MANAGEMENT}/${RoutingSegment.MAIN}`,
                    categories: [RoutingSegment.TASK_MANAGEMENT],
                    module: CurafidaFeatureEnum.task,
                    role: [UserRoles.manage_therapy_template],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Aufgabenvorlage',
                    pageId: `${PageName.TASK}_${PageName.EXERCISE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TASK_MANAGEMENT}/${RoutingSegment.TASK_TEMPLATES}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.task,
                    role: [UserRoles.manage_exercise],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Aufgabenplanvorlage',
                    pageId: `${PageName.TASK}_${PageName.TEMPLATE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.TASK_MANAGEMENT}/${RoutingSegment.TASK_PLAN_TEMPLATES}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.task,
                    role: [UserRoles.manage_therapy_template],
                    configuration: {
                        showPageTitle: true,
                    },
                },

                // Learning
                {
                    title: 'Lernen',
                    pageId: `${PageName.LEARNING}_${PageName.MAIN}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.LEARNING_MANAGEMENT}/${RoutingSegment.MAIN}`,
                    categories: [RoutingSegment.LEARNING_MANAGEMENT],
                    module: CurafidaFeatureEnum.learning,
                    role: [UserRoles.manage_therapy_template],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                // PROGRAM
                {
                    title: 'Programme',
                    pageId: `${PageName.PROGRAM}_${PageName.LIST}`,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/` +
                        `${RoutingSegment.PROGRAM_MANAGEMENT}/${RoutingSegment.MAIN}`,
                    categories: [RoutingSegment.PROGRAM_MANAGEMENT],
                    module: CurafidaFeatureEnum.program,
                    role: [UserRoles.manage_therapy_template],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Programmvorlage',
                    pageId: `${PageName.PROGRAM}_${PageName.TEMPLATE}_${PageName.DETAIL}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.PROGRAM_MANAGEMENT}/${RoutingSegment.PROGRAM_TEMPLATES}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.program,
                    role: [UserRoles.manage_therapy, UserRoles.manage_therapy_template],
                    configuration: {
                        showPageTitle: true,
                    },
                },

                // COURSE
                {
                    title: 'Sitzungen',
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.MEETINGS}`,
                    categories: [RoutingSegment.ADMINISTRATION],
                    module: CurafidaFeatureEnum.course,
                    pageId: PageName.COURSE_ADMINISTRATION,
                    role: [UserRoles.manage_therapy_template],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Übungen detail',
                    pageId: PageName.EXERCISE_DETAIL,
                    url:
                        `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.MEETINGS}/` +
                        `${RoutingSegment.EXERCISE}/${RoutingSegment.APPOINTMENT_PLAN}`,
                    categories: [RoutingSegment.MEETINGS, RoutingSegment.EXERCISE],
                    module: CurafidaFeatureEnum.course,
                    role: [UserRoles.manage_exercise, UserRoles.manage_therapy],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Sitzungsvorlage detail',
                    pageId: PageName.EXERCISE_DETAIL,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.MEETINGS}/
                    ${RoutingSegment.TEMPLATE}`,
                    categories: [RoutingSegment.MEETINGS],
                    module: CurafidaFeatureEnum.course,
                    role: [UserRoles.manage_therapy],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Kursvorlagen detail',
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.MEETINGS}/${RoutingSegment.THERAPY_TEMPLATE}/${RoutingSegment.DETAIL}`,
                    categories: [RoutingSegment.MEETINGS, RoutingSegment.THERAPY_TEMPLATE],
                    module: CurafidaFeatureEnum.course,
                    pageId: PageName.COURSE_TEMPLATE_DETAIL,
                    role: [UserRoles.manage_therapy_template],
                    configuration: {
                        showPageTitle: true,
                    },
                },
                {
                    title: 'Notizen',
                    pageId: `${PageName.NOTE}`,
                    url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ADMINISTRATION}/${RoutingSegment.NOTE_MANAGEMENT}`,
                    categories: [RoutingSegment.NOTE_MANAGEMENT],
                    module: CurafidaFeatureEnum.note,
                    role: [UserRoles.manage_note_characteristics],
                    configuration: {
                        menuVisibility: true,
                        headerVisibility: true,
                        showPageTitle: true,
                    },
                },
            ],
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.PATIENT}_${PageName.AUTO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.AUTO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeatureEnum.training,
            role: [UserRoles.PATIENT],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.PATIENT}_${PageName.AUTO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CAREGIVER_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.AUTO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeatureEnum.training,
            role: [UserRoles.manage_therapy],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.THERAPIST}_${PageName.INFO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CAREGIVER_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.INFO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeatureEnum.training,
            role: [UserRoles.manage_therapy],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.PATIENT}_${PageName.INFO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.INFO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeatureEnum.training,
            role: [UserRoles.PATIENT],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.MANUAL}_${PageName.AUTO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONCEPT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.AUTO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeatureEnum.training,
            role: [UserRoles.manage_therapy],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.MANUAL}_${PageName.INFO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONCEPT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.INFO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeatureEnum.training,
            role: [UserRoles.manage_therapy],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Training',
            pageId: `${PageName.TRAINING}_${PageName.MANUAL}_${PageName.INFO}`,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONCEPT}/${RoutingSegment.TRAINING_MANAGEMENT}/${RoutingSegment.INFO}`,
            categories: [RoutingSegment.TRAINING_MANAGEMENT],
            module: CurafidaFeatureEnum.training,
            role: [UserRoles.manage_therapy],
            showTitle: true,
            configuration: {
                showPageTitle: true,
            },
        },

        {
            title: 'Messung starten',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.MEASUREMENT_MANAGEMENT}/${RoutingSegment.EXPERT_MODE}`,
            pageId: PageName.MEASUREMENT_EXPERT,
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.MEASUREMENT],
            module: CurafidaFeatureEnum.measurement,
            configuration: {
                menuVisibility: false,
                headerVisibility: false,
                showPageTitle: true,
                hasHelpIcon: true,
            },
        },
        {
            title: 'Messung durchführen',
            url: `/${RoutingSegment.MEMBER}/guided-measurement`,
            pageId: 'guided-measurement',
            role: [UserRoles.PATIENT],
            categories: [RoutingSegment.MEASUREMENT],
            module: CurafidaFeatureEnum.measurement,
            configuration: {
                menuVisibility: false,
                headerVisibility: false,
                showPageTitle: true,
            },
        },
        {
            title: 'Messungen',
            pageId: PageName.MEASUREMENT_DETAIL,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.MEASUREMENT_MANAGEMENT}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.measurement,
            role: [UserRoles.read_measurement, UserRoles.PATIENT, UserRoles.SUPERVISOR],
            configuration: {
                showPageTitle: true,
            },
        },

        {
            title: 'Normbereiche einstellen',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.MEASUREMENT_MANAGEMENT}/${RoutingSegment.NORM_VALUES}`,
            pageId: PageName.NORM_VALUES,
            role: [UserRoles.create_measurement],
            categories: [RoutingSegment.MEASUREMENT],
            module: CurafidaFeatureEnum.measurement,
            configuration: {
                menuVisibility: false,
                headerVisibility: false,
                showPageTitle: true,
                hasHelpIcon: false,
            },
        },
        {
            title: 'ECG',
            pageId: 'ecg',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.MEASUREMENT_MANAGEMENT}/${RoutingSegment.ECG}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.measurement,
            role: [UserRoles.manage_measurement, UserRoles.PATIENT],
            configuration: {
                menuVisibility: false,
                headerVisibility: false,
                showPageTitle: true,
            },
        },
        {
            title: 'Konsil',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.CONSULTATION_MANAGEMENT}/new`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.consultation,
            role: [UserRoles.manage_consultation],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Konsil',
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.CONSULTATION_MANAGEMENT}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.consultation,
            role: [UserRoles.read_consultation],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Auftrag',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.ORDER_MANAGEMENT}/new`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.order,
            role: [UserRoles.manage_order],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Auftrag',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.ORDER_MANAGEMENT}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.order,
            role: [UserRoles.read_order],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Screening',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.SCREENING_MANAGEMENT}/new`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.screening,
            //TODO: Check correct rights for the page #3993
            //// https://gitlab.ztm-badkissingen.de/curafida/development/ionic-common/-/issues/6234
            role: [UserRoles.write_medical_patientdata, UserRoles.create_supervised_patient],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Medikation',
            pageId: PageName.MEDICATION,
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.MEDICATION_MANAGEMENT}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.medication,
            role: [UserRoles.PATIENT, UserRoles.read_medication, UserRoles.SUPERVISOR],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Notiz',
            pageId: PageName.NOTE,
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.NOTE_MANAGEMENT}/${RoutingSegment.DETAIL}`,
            categories: [RoutingSegment.PATIENT_MANAGEMENT],
            module: CurafidaFeatureEnum.note,
            role: [UserRoles.PATIENT, UserRoles.read_note, UserRoles.SUPERVISOR],
            configuration: {
                showPageTitle: true,
            },
        },
        {
            title: 'Mobilität',
            pageId: PageName.MOBILITY,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/${RoutingSegment.MOBILITY}`,
            categories: [RoutingSegment.MOBILITY],
            module: CurafidaFeatureEnum.mobility,
            role: [UserRoles.PATIENT, UserRoles.SUPERVISOR, UserRoles.manage_therapy],
            configuration: {},
        },
        {
            title: 'Lernen',
            pageId: PageName.LEARNING,
            url:
                `/${RoutingSegment.MEMBER}/${RoutingSegment.PATIENT_MANAGEMENT}/` +
                `${RoutingSegment.LEARNING_MANAGEMENT}/${RoutingSegment.LIST}`,
            categories: [RoutingSegment.LEARNING_MANAGEMENT],
            module: CurafidaFeatureEnum.learning,
            role: [UserRoles.PATIENT],
            configuration: {
                showPageTitle: false,
                menuVisibility: false,
            },
        },
        //TODO : Check if roles can be removed
        {
            title: 'Profil',
            pageId: PageName.PROFILE,
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.PROFILE}`,
            categories: [RoutingSegment.PROFILE],
            module: CurafidaFeatureEnum.common,
            role: [
                UserRoles.PATIENT,
                UserRoles.CAREGIVER,
                UserRoles.USER_MANAGER,
                UserRoles.CATALOG_MANAGER,
                UserRoles.PHYSICIAN,
                UserRoles.ADMIN,
                UserRoles.SUPERVISOR,
                UserRoles.ANALYST,
                UserRoles.KNOWLEDGE_MANAGER,
            ],
            configuration: {
                menuVisibility: true,
                headerVisibility: false,
                showPageTitle: true,
            },
        },
        {
            title: 'Wissensportal-Technology-details',
            logo: '',
            url: `/${RoutingSegment.MEMBER}/${RoutingSegment.KNOWLEDGE}/${RoutingSegment.DETAIL}`,
            pageId: RoutingSegment.KNOWLEDGE + '/' + RoutingSegment.DETAIL,
            role: [UserRoles.manage_chat, UserRoles.KNOWLEDGE_MANAGER],
            module: CurafidaFeatureEnum.knowledge,
            categories: [RoutingSegment.KNOWLEDGE],
            configuration: {
                menuVisibility: false,
                headerVisibility: false,
                showPageTitle: true,
            },
        },
    ];
    appId: undefined;
    keycloak: {
        url: string | null;
        realm: string | null;
        clientId: string | null;
    };
    theme: object;
}
