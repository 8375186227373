import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { TherapySession } from '../../../therapy/entities/therapy-session/therapy-session';
import { KioskTrainingVideoModalComponent } from '../kiosk-training-video-modal/kiosk-training-video-modal.component';
import { Content, ContentMetaDataType } from '../../../therapy/entities/content';
import { TranslateService } from '@ngx-translate/core';
import { UserExerciseSessionsService } from '../../../therapy/services/user-exercise-sessions';
import { User } from '../../../auth/entities/user';
import {
    ExerciseSessionOfUser,
    ExerciseSessionState,
    ExerciseSessionUserState,
} from '../../../therapy/entities/exerciseSession';
import { UsersTherapySessionsService } from '../../../therapy/services/users-therapy-sessions/users-therapy-sessions.service';
import { TherapyWithTherapySessions } from '../../../therapy/entities/therapy';
import { FeedbackFlag } from '../../../therapy/entities/feedback/feedback-comment';
import { format } from 'date-fns';
import { ContentFormatType } from '../../../common/entities/content-format-type';
import { ExerciseContentsService } from '../../../therapy/services/exercise-contents';
import { KioskModalService } from '../../services/kiosk-modal.service';

@Component({
    selector: 'lib-kiosk-training-modal',
    templateUrl: './kiosk-training-modal.component.html',
    styleUrls: ['./kiosk-training-modal.component.scss'],
})
export class KioskTrainingModalComponent implements OnInit {
    @Input()
    training: TherapyWithTherapySessions;
    title: string;
    therapySession: TherapySession;
    exerciseIndex = 0;
    isTrainingFinished = false;
    srcImg: string;
    iconColor: string;
    feedbackForUser: string;
    pauseTrainingModal: HTMLIonModalElement;
    startVideoExerciseModal: HTMLIonModalElement;
    nextExerciseModal: HTMLIonModalElement;
    timer: string;
    loggedInUser: User;
    private readonly timerInterval: NodeJS.Timeout;

    constructor(
        private modalCtrl: ModalController,
        private params: NavParams,
        private translateService: TranslateService,
        private exerciseContentService: ExerciseContentsService,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private usersTherapySessionsService: UsersTherapySessionsService,
        private kioskModalService: KioskModalService,
    ) {
        this.timerInterval = setInterval(() => {
            this.timer = format(new Date(), 'HH:mm:ss');
            if (this.timer === '00:00:00') {
                this.dismissModal();
            }
        }, 1000);
    }

    async ngOnInit(): Promise<void> {
        this.initializeComponent();
    }

    private async initializeComponent(): Promise<void> {
        this.loggedInUser = this.params.get('user');
        this.title = this.training.title;
        this.therapySession = this.training.runningTherapySession.items[0];
        this.sortExerciseSessions();
        this.exerciseIndex = this.countFinishedExercises();
        await this.processExerciseSessions();
    }

    private sortExerciseSessions(): void {
        this.therapySession.exerciseSessionsOfUser.sort(
            (a, b) => a.exerciseSession.therapyExercise.order - b.exerciseSession.therapyExercise.order,
        );
    }

    private countFinishedExercises(): number {
        return this.therapySession.exerciseSessionsOfUser.filter((session) =>
            [ExerciseSessionUserState.FINISHED, ExerciseSessionUserState.PATIENT_CANCELLED].includes(
                session.exerciseSessionUserState,
            ),
        ).length;
    }

    private async processExerciseSessions(): Promise<void> {
        for (const session of this.therapySession.exerciseSessionsOfUser) {
            await this.processExerciseSession(session);
        }
    }

    private async processExerciseSession(exerciseSessionOfUser: ExerciseSessionOfUser): Promise<void> {
        await this.updateExerciseThumbnail(exerciseSessionOfUser);
        this.filterExerciseContents(exerciseSessionOfUser);
        this.setExerciseParameters(exerciseSessionOfUser);
    }

    private async updateExerciseThumbnail(exerciseSessionOfUser: ExerciseSessionOfUser): Promise<void> {
        const thumbnail = exerciseSessionOfUser.exerciseSession.exercise.contents.find(
            (content: Content) => content.jsonData?.contentMetaDataType === ContentMetaDataType.THUMBNAIL,
        );
        if (thumbnail) {
            const url = await this.exerciseContentService.getObjectURLContentFromUrl(
                thumbnail.uuid,
                ContentFormatType.MEDIUM_SIZE,
            );
            exerciseSessionOfUser.exerciseSession.exercise.url = url;
            exerciseSessionOfUser.exerciseSession.exercise.mimeType = thumbnail.mimeType;
        }
    }

    private filterExerciseContents(exerciseSessionOfUser: ExerciseSessionOfUser): void {
        if (exerciseSessionOfUser.exerciseSession?.exercise?.contents) {
            exerciseSessionOfUser.exerciseSession.exercise.contents =
                exerciseSessionOfUser.exerciseSession.exercise.contents.filter(
                    (content: Content) => content.jsonData?.contentMetaDataType !== ContentMetaDataType.THUMBNAIL,
                );
        }
    }

    private setExerciseParameters(exerciseSessionOfUser: ExerciseSessionOfUser): void {
        const parameters = [];
        if (exerciseSessionOfUser.exerciseGoal.series) {
            parameters.push(`${exerciseSessionOfUser.exerciseGoal.series} x`);
        }
        if (exerciseSessionOfUser.exerciseGoal.duration) parameters.push(exerciseSessionOfUser.exerciseGoal.duration);
        if (exerciseSessionOfUser.exerciseGoal.durationUnit) {
            parameters.push(this.translateService.instant(exerciseSessionOfUser.exerciseGoal.durationUnit));
        }
        exerciseSessionOfUser.exerciseSession.exercise.exerciseParameters = parameters.join(' ');
    }

    dismissModal(): void {
        this.pauseTrainingModal?.onDidDismiss();
        this.startVideoExerciseModal?.onDidDismiss();
        this.nextExerciseModal?.onDidDismiss();
        this.modalCtrl.dismiss();
    }

    async startExercise(): Promise<void> {
        const currentExercise = this.therapySession.exerciseSessionsOfUser[this.exerciseIndex];
        this.startVideoExerciseModal = await this.modalCtrl.create({
            component: KioskTrainingVideoModalComponent,
            cssClass: 'full-screen-modal',
            componentProps: {
                exerciseSessionOfUser: currentExercise,
                training: this.training,
                exerciseIndex: this.exerciseIndex,
                therapySession: this.therapySession,
            },
        });
        await this.startVideoExerciseModal.present();

        const { data } = await this.startVideoExerciseModal.onDidDismiss();
        if (!data) return;

        if (data.action === 'nextExercise') {
            await this.handleNextExercise(data);
        } else if (data.action === 'cancelExercise') {
            await this.handleCancelExercise();
        }
    }

    private async handleNextExercise(data: any): Promise<void> {
        const exerciseSessionOfUser = this.therapySession.exerciseSessionsOfUser[this.exerciseIndex];

        if (
            [ExerciseSessionUserState.PLANNED, ExerciseSessionUserState.ACTIVE].includes(
                exerciseSessionOfUser.exerciseSessionUserState,
            )
        ) {
            await this.userExerciseSessionsService.postUsersExerciseSessionActivate(
                this.loggedInUser.username,
                Number(exerciseSessionOfUser.exerciseSession_id),
            );
            exerciseSessionOfUser.exerciseSessionUserState = ExerciseSessionUserState.ACTIVE;
        }

        if (exerciseSessionOfUser.exerciseSession.exerciseSessionState === ExerciseSessionState.ACTIVE) {
            if (data.feedBack != null) {
                const feedbackResponse = await this.userExerciseSessionsService.postUsersExerciseSessionFeedback(
                    this.loggedInUser.username,
                    Number(exerciseSessionOfUser.exerciseSession_id),
                    data.feedBack,
                );
                exerciseSessionOfUser.feedback = feedbackResponse;
            }

            await this.userExerciseSessionsService.postUsersExerciseSessionFinish(
                this.loggedInUser.username,
                Number(exerciseSessionOfUser.exerciseSession_id),
            );
            exerciseSessionOfUser.exerciseSessionUserState = ExerciseSessionUserState.FINISHED;
        }

        if (this.exerciseIndex === this.training.exercises.length - 1) {
            this.setConclusionPictureAndText();
        } else {
            this.changeExerciseIndexValue();
        }
    }

    private async handleCancelExercise(): Promise<void> {
        if (this.exerciseIndex <= this.training.exercises.length - 1) {
            await this.userExerciseSessionsService.postExerciseSessionUserPatientCancel(
                this.loggedInUser.username,
                Number(this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSession_id),
            );
            this.therapySession.exerciseSessionsOfUser[this.exerciseIndex].exerciseSessionUserState =
                ExerciseSessionUserState.PATIENT_CANCELLED;

            if (this.exerciseIndex === this.training.exercises.length - 1) {
                this.setConclusionPictureAndText();
            }

            return this.changeExerciseIndexValue();
        }

        this.isTrainingFinished = true;
    }

    changeExerciseIndexValue(): void {
        if (this.exerciseIndex < this.training.exercises.length - 1) this.exerciseIndex++;
    }

    async pauseTraining(): Promise<void> {
        this.pauseTrainingModal = await this.kioskModalService.definePauseTrainingModal();
        await this.pauseTrainingModal.present();
        const { data } = await this.pauseTrainingModal.onDidDismiss();
        if (data?.action === 'pauseTraining') {
            this.dismissModal();
        }
    }

    async finishedTraining(): Promise<void> {
        this.dismissModal();
    }

    setConclusionPictureAndText(): void {
        this.iconColor = 'medium';
        if (
            this.therapySession.exerciseSessionsOfUser.filter(
                (i) => i.exerciseSessionUserState === ExerciseSessionUserState.PATIENT_CANCELLED,
            ).length === this.therapySession.exerciseSessionsOfUser.length
        ) {
            this.title = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.FINISHED_NONE_EXERCISES');
            this.srcImg = 'assets/imgs/man-running.png';
            this.isTrainingFinished = true;
            this.feedbackForUser = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.REINRORCED_FEEDBACK');
            return;
        } else {
            if (
                this.therapySession.exerciseSessionsOfUser.filter(
                    (i) => i.exerciseSessionUserState === ExerciseSessionUserState.FINISHED,
                ).length === this.therapySession.exerciseSessionsOfUser.length
            ) {
                this.title = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.FINISHED_ALL_EXERCISES');
            } else {
                this.title = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.FINISHED_SOME_EXERCISES', {
                    exercises: this.therapySession.exerciseSessionsOfUser.filter(
                        (i) => i.exerciseSessionUserState === ExerciseSessionUserState.FINISHED,
                    ).length,
                    allExercises: this.therapySession.exerciseSessionsOfUser.length,
                });
            }
            this.srcImg = 'assets/imgs/training-man.png';
            this.feedbackForUser = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.POSITIVE_FEEDBACK');
            const redFlag = this.therapySession.exerciseSessionsOfUser.find(
                (exercise) => exercise?.feedback?.borgFlag === FeedbackFlag.RED,
            );
            if (redFlag) {
                this.srcImg = 'assets/imgs/man-exhausted.png';
                this.iconColor = 'danger';
                this.feedbackForUser = this.translateService.instant('KIOSK.TRAINING.CONCLUSION.ALERT_FEEDBACK');
            }
            this.isTrainingFinished = true;
            return;
        }
    }
}
