import { Component, OnInit } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { FeedbackCommentsService } from '../../../therapy/services/feedback-comments';
import { PaginatedResponse } from '../../../common/entities/paginated-response';
import { FeedbackComment } from '../../../therapy/entities/feedback/feedback-comment';
import { NgForOf, NgIf } from '@angular/common';
import { TranslationModule } from '../../../translation/translation.module';
import { CommonComponentsModule } from '../../../common/components/common-components.module';

@Component({
    standalone: true,
    selector: 'lib-kiosk-feedback-comment-modal',
    templateUrl: './kiosk-feedback-comment-modal.component.html',
    styleUrls: ['./kiosk-feedback-comment-modal.component.scss'],
    imports: [IonicModule, NgIf, TranslationModule, NgForOf, CommonComponentsModule],
})
export class KioskFeedbackCommentModalComponent implements OnInit {
    customerComments: PaginatedResponse<FeedbackComment[]>;
    selectedComment: FeedbackComment;
    private element: HTMLElement;

    constructor(
        private modalCtrl: ModalController,
        private feedbackCommentsService: FeedbackCommentsService,
    ) {
        this.selectedComment = new FeedbackComment();
        this.selectedComment.uuid = '';
    }

    async ngOnInit(): Promise<void> {
        this.feedbackCommentsService.getFeedbackComments(0, 8).subscribe((customerComments) => {
            this.customerComments = customerComments;
            this.setCss();
        });
    }

    async dismissModal() {
        delete this.selectedComment.deletedDate;
        await this.modalCtrl.dismiss(this.selectedComment as FeedbackComment);
    }

    selectComment(comment: FeedbackComment) {
        this.selectedComment = comment;
    }

    setCss(): void {
        this.element = document?.getElementById('KioskFeedbackModalComponent');
        if (this.customerComments.items.length <= 4 && this.element) {
            this.element.classList.remove('feedback-modal');
            this.element.classList.add('feedback-modal-small');
        }
    }
}
