import { Component, ViewChild } from '@angular/core';
import { Logger, LoggingService } from '../../../../../logging/logging.service';
import { ModalController } from '@ionic/angular';
import { TrainingUsingTyp } from '../../../../entities/training/training';
import { UserRoles } from '../../../../../auth/entities/user';
import { ExerciseSessionState } from '../../../../entities/exerciseSession';
import { TrainingExerciseProcessComponent } from '../training-exercise/training-exercise-process.component';
import { TrainingPlanResource } from '../../../../entities/training/training-plan.resource';

@Component({
    selector: 'lib-training-process',
    templateUrl: './training-process.component.html',
    styleUrls: ['./training-process.component.scss'],
})
export class TrainingProcessComponent {
    @ViewChild('TrainingExerciseProcess') trainingExerciseProcess: TrainingExerciseProcessComponent;
    title = 'Vorbereitung';
    readonly log: Logger;
    therapyId: number;
    trainingPlan: TrainingPlanResource;
    TrainingUsingTyp = TrainingUsingTyp;
    currentTrainingProcessStep: number = TrainingProcessStep.INFO;
    TrainingProcessStep = TrainingProcessStep;
    UserRoles = UserRoles;
    ExerciseSessionState = ExerciseSessionState;
    therapySessionUuid: string;

    constructor(
        private loggingService: LoggingService,
        private modalCtrl: ModalController,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async changeCurrentProcessStep(therapySessionUuid?: string): Promise<boolean> {
        if (therapySessionUuid) this.therapySessionUuid = therapySessionUuid;
        if (this.currentTrainingProcessStep === TrainingProcessStep.CONCLUSION) return this.modalCtrl.dismiss();
        this.currentTrainingProcessStep++;
    }
}

enum TrainingProcessStep {
    INFO,
    EXERCISES,
    CONCLUSION,
}
