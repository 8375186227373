import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiService } from '../../../api';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { AccountType, User } from '../../../auth/entities/user';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserSearchParams } from '../../entities/user-search-params';

@Injectable({
    providedIn: 'root',
})
export class CaregiverService {
    public static options = { headers: new HttpHeaders(), withCredentials: false };

    constructor(protected http: HttpClient) {}

    async fetchCaredPatients(
        username: string,
        args?: {
            offset?: number;
            limit?: number;
            disabled?: boolean;
            filter?: string;
            sortOrder?: SortOrder;
            sortBy?: SortBy;
            tagUuids?: string[];
            withTags?: boolean;
            withLastActivityDate?: boolean;
            accountType?: AccountType;
            emailVerified?: boolean;
            hasTherapy?: boolean;
            finished?: boolean;
            inactive?: boolean;
        },
    ): Promise<PaginatedResponse<User[]>> {
        const url = new URL(`${ApiService.url}caregivers/${username}/patients`);
        // build query param string
        if (args.offset) url.searchParams.set('offset', `${args.offset}`);
        if (args.limit) url.searchParams.set('limit', `${args.limit}`);
        if (args.filter) url.searchParams.set('filter', `${args.filter}`);
        if (args.sortBy) url.searchParams.set('sortBy', `${args.sortBy}`);
        if (args.sortOrder) url.searchParams.set('sortOrder', `${args.sortOrder}`);
        if (args.withTags != null) url.searchParams.set('withTags', `${args.withTags}`);
        if (args.tagUuids?.length > 0) {
            for (const tagUuid of args.tagUuids) {
                url.searchParams.append('tagUuids', `${tagUuid}`);
            }
        }
        if (args.withLastActivityDate != null) {
            url.searchParams.set('withLastActivityDate', `${args.withLastActivityDate}`);
        }
        if (args.disabled != null) url.searchParams.set('disabled', `${args.disabled}`);
        if (args.accountType) url.searchParams.set('accountType', `${args.accountType}`);
        if (args.emailVerified != null) url.searchParams.set('emailVerified', `${args.emailVerified}`);
        if (args.hasTherapy != null) url.searchParams.set('hasTherapy', `${args.hasTherapy}`);
        if (args.finished != null) url.searchParams.set('finished', `${args.finished}`);
        if (args.inactive != null) url.searchParams.set('inactive', `${args.inactive}`);

        return this.http.get<PaginatedResponse<User[]>>(url.toString()).toPromise();
    }

    fetchCaredPatients$(searchParams: EventEmitter<UserSearchParams>): Observable<PaginatedResponse<User[]>> {
        return searchParams.pipe(
            map((searchParams) => {
                const url = new URL(`${ApiService.url}caregivers/${searchParams.username}/patients`);
                // build query param string
                if (searchParams.offset) url.searchParams.set('offset', `${searchParams.offset}`);
                if (searchParams.limit) url.searchParams.set('limit', `${searchParams.limit}`);
                if (searchParams.filter) url.searchParams.set('filter', `${searchParams.filter}`);
                if (searchParams.sortBy) url.searchParams.set('sortBy', `${searchParams.sortBy}`);
                if (searchParams.sortOrder) url.searchParams.set('sortOrder', `${searchParams.sortOrder}`);
                if (searchParams.withTags != null) {
                    url.searchParams.set('withTags', `${searchParams.withTags}`);
                }

                if (searchParams.tagUuids?.length > 0) {
                    for (const tagUuid of searchParams.tagUuids) {
                        url.searchParams.append('tagUuids', `${tagUuid}`);
                    }
                }
                if (searchParams.withLastActivityDate != null) {
                    url.searchParams.set('withLastActivityDate', `${searchParams.withLastActivityDate}`);
                }
                if (searchParams.disabled != null) {
                    url.searchParams.set('disabled', `${searchParams.disabled}`);
                }
                if (searchParams.accountType) {
                    url.searchParams.set('accountType', `${searchParams.accountType}`);
                }
                if (searchParams.emailVerified != null) {
                    url.searchParams.set('emailVerified', `${searchParams.emailVerified}`);
                }
                if (searchParams.hasTherapy != null) {
                    url.searchParams.set('hasTherapy', `${searchParams.hasTherapy}`);
                }
                if (searchParams.finished != null) {
                    url.searchParams.set('finished', `${searchParams.finished}`);
                }
                if (searchParams.inactive != null) {
                    url.searchParams.set('inactive', `${searchParams.inactive}`);
                }
                return url;
            }),
            switchMap((url) => this.http.get<PaginatedResponse<User[]>>(url.toString())),
        );
    }
}
