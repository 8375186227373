import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExerciseSessionsService } from '../../../../services/exercise-sessions';
import { CurafidaAuthService } from '../../../../../auth/services';
import { UsersTherapiesService } from '../../../../services/users-therapies';
import { User, UserRoles } from '../../../../../auth/entities/user';
import { TrainingUsingTyp } from '../../../../entities/training/training';
import { Therapy } from '../../../../entities/therapy';
import { TherapySession, TherapySessionState } from '../../../../entities/therapy-session/therapy-session';
import { UsersTherapySessionsService } from '../../../../services/users-therapy-sessions/users-therapy-sessions.service';
import { PaginatedResponse } from '../../../../../common/entities/paginated-response';
import { ExerciseSessionState } from '../../../../entities/exerciseSession';
import { ContentMetaDataType } from '../../../../entities/content';
import { ContentFormatType } from '../../../../../common/entities/content-format-type';
import { ExerciseContentsService } from '../../../../services/exercise-contents';
import { SafeUrl } from '@angular/platform-browser';
import { StyleService } from '../../../../../common/services/style/style.service';

@Component({
    selector: 'lib-training-conclusion',
    templateUrl: './training-conclusion.component.html',
    styleUrls: ['./training-conclusion.component.scss'],
})
export class TrainingConclusionProcessComponent implements OnInit {
    title = 'Vorbereitung';
    loggedInUser: User;
    UserRoles = UserRoles;
    @Input()
    trainingUsingTyp: TrainingUsingTyp;
    @Input()
    objectId: number;
    @Output()
    emitNextProcessStep: EventEmitter<boolean> = new EventEmitter<boolean>();
    training: Therapy;
    therapySession: PaginatedResponse<TherapySession[]>;
    ExerciseSessionState = ExerciseSessionState;
    @Input()
    therapySessionUuid: string;

    exercises: ExerciseConclusion[];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private exerciseSessionsService: ExerciseSessionsService,
        private readonly authService: CurafidaAuthService,
        private userTherapyService: UsersTherapiesService,
        private usersTherapySessionService: UsersTherapySessionsService,
        private exerciseContentService: ExerciseContentsService,
        readonly styleService: StyleService,
    ) {
        this.loggedInUser = this.authService.getSession().user;
    }

    async backToList(): Promise<void> {
        if (this.trainingUsingTyp === TrainingUsingTyp.CAREGIVER) {
            await this.exerciseSessionsService.postExerciseSessionFinish(Number(this.objectId));
            this.emitNextProcessStep.emit();
        }
        if (this.trainingUsingTyp === TrainingUsingTyp.PATIENT) {
            await this.userTherapyService.getUserTherapy(this.authService.getSession().user.username, this.objectId);
            this.emitNextProcessStep.emit();
        }
    }

    async ngOnInit(): Promise<void> {
        this.therapySession = await this.usersTherapySessionService.getUserTherapySessions({
            username: this.authService.getSession().user.username,
            therapyId: this.objectId,
            offset: 0,
            limit: 1,
            state: TherapySessionState.COMPLETED,
        });

        const currentTherapySession = this.therapySession.items.filter((i) => i.uuid === this.therapySessionUuid)[0];
        const exercises = [];
        for (const exerciseSessionsOfUser of currentTherapySession.exerciseSessionsOfUser) {
            const exercise = new ExerciseConclusion();
            const thumbnailIndex = exerciseSessionsOfUser.exerciseSession.exercise.contents.findIndex(
                (i) => i.jsonData?.contentMetaDataType === ContentMetaDataType.THUMBNAIL,
            );
            if (thumbnailIndex != -1) {
                exercise.url = await this.exerciseContentService.getObjectURLContentFromUrl(
                    exerciseSessionsOfUser.exerciseSession.exercise.contents[thumbnailIndex].uuid,
                    ContentFormatType.THUMBNAIL,
                );
            }
            exercise.state = exerciseSessionsOfUser.exerciseSession.exerciseSessionState;
            exercise.title = exerciseSessionsOfUser.exerciseSession.exercise.title;
            exercise.order = exerciseSessionsOfUser.exerciseSession.therapyExercise.order;
            exercises.push(exercise);
        }
        exercises.sort((e1, e2) => {
            return e1?.order - e2?.order;
        });
        this.exercises = exercises;
    }
}

export class ExerciseConclusion {
    title: string;
    state: ExerciseSessionState;
    url: SafeUrl;
    order: number;
}
