import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExerciseSessionState } from '../../../../entities/exerciseSession';
import {
    FeedbackCommentUnit,
    PainUnit,
    PhysicalExerciseGoalDto,
    StressUnit,
} from '../../../../entities/exerciseGoal/exerciseGoalDto';
import { TrainingState } from '../../../../entities/therapy';
import { PaginatedResponse } from '../../../../../common/entities/paginated-response';
import { TherapySession, TherapySessionState } from '../../../../entities/therapy-session/therapy-session';
import { User, UserRoles } from '../../../../../auth/entities/user';
import { Logger, LoggingService } from '../../../../../logging/logging.service';
import { ModalAlertService } from '../../../../../common/services/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, ModalController, PopoverController } from '@ionic/angular';
import { CurafidaAuthService } from '../../../../../auth/services';
import { ExerciseSessionsService } from '../../../../services/exercise-sessions';
import { ExercisesService } from '../../../../services/exercises';
import { ToolbarService } from '../../../../../common/services/toolbar/toolbar.service';
import { UsersTherapySessionsService } from '../../../../services/users-therapy-sessions/users-therapy-sessions.service';
import { RoutingSegment } from '../../../../../common/entities/routing-segment';
import { UserExerciseSessionsService } from '../../../../services/user-exercise-sessions';
import { FeedbackComment, FeedbackFlag } from '../../../../entities/feedback/feedback-comment';
import { FeedBackDto } from '../../../../entities/feedback';
import { Content, ContentMetaDataType } from '../../../../entities/content';
import {
    TrainingContent,
    TrainingDefinition,
    TrainingExercise,
    TrainingUsingTyp,
} from '../../../../entities/training/training';
import { ModalConfig } from '../../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../../common/entities/modal/modal-button';
import { FeedbackCommentsService } from '../../../../services/feedback-comments';
import { StyleService } from '../../../../../common/services/style/style.service';
import { FeedbackModalComponent } from '../../feedback/feedback-modal/feedback-modal.component';
import {
    ActionButton,
    ActionDivider,
    ActionItemType,
    ActionMenuItem,
} from '../../../../../table/entities/action-menu.item';
import { CurafidaPopoverSelectionComponent } from '../../../../../table/components/curafida-popover-selection/curafida-popover-selection.component';
import { ActionType } from '../../../../../table/entities/table';

@Component({
    selector: 'lib-training-exercise-process',
    templateUrl: './training-exercise-process.component.html',
    styleUrls: ['./training-exercise-process.component.scss'],
})
export class TrainingExerciseProcessComponent implements OnInit {
    title = 'Vorbereitung';
    trainingDefinition: TrainingDefinition;
    loadingExercisesCompleted = false;
    swipeFlag = true;
    loggedInUser: User;
    ExerciseSessionState = ExerciseSessionState;
    activeTrainingExercise: TrainingExercise;
    waitingExercise = 0;
    unfinishedExercises = 1;
    isFirstExercise = true;
    UserRoles = UserRoles;
    RoutingSegment = RoutingSegment;
    @Input()
    trainingUsingTyp: TrainingUsingTyp;
    @Input()
    objectId: number;
    @Output()
    emitNextProcessStep: EventEmitter<boolean> = new EventEmitter<boolean>();
    index = 0;
    protected readonly log: Logger;
    private customerComments: PaginatedResponse<FeedbackComment[]>;

    constructor(
        private modalAlertService: ModalAlertService,
        public router: Router,
        private loggingService: LoggingService,
        private authService: CurafidaAuthService,
        protected activatedRoute: ActivatedRoute,
        private exerciseSessionsService: ExerciseSessionsService,
        private exerciseService: ExercisesService,
        private usersTherapySessionService: UsersTherapySessionsService,
        private toolbarService: ToolbarService,
        private feedbackCommentsService: FeedbackCommentsService,
        private modalCtrl: ModalController,
        private userExerciseSessionsService: UserExerciseSessionsService,
        private actionSheetController: ActionSheetController,
        private styleService: StyleService,
        private popoverController: PopoverController,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit(): Promise<void> {
        this.loggedInUser = this.authService.getSession().user;
        this.feedbackCommentsService.getFeedbackComments(0, 30).subscribe((customerComments) => {
            this.customerComments = customerComments;
        });

        this.trainingDefinition = new TrainingDefinition();
        if (this.trainingUsingTyp === TrainingUsingTyp.PATIENT) {
            let therapySession: TherapySession;
            const therapySessionList = await this.usersTherapySessionService.getUserTherapySessions({
                username: this.authService.getSession().user.username,
                therapyId: this.objectId,
                offset: 0,
                limit: 1,
                state: TherapySessionState.RUNNING,
            });
            if (therapySessionList.total === 0) {
                therapySession = await this.usersTherapySessionService.createUserTherapySession(
                    this.authService.getSession().user.username,
                    this.objectId,
                );
            } else {
                therapySession = therapySessionList.items[0];
            }
            this.trainingDefinition.title = therapySession.therapy.title;

            this.trainingDefinition.exercises = [];
            this.trainingDefinition.tags = [];
            therapySession.exerciseSessionsOfUser = therapySession.exerciseSessionsOfUser.sort((esou1, esou2) => {
                return esou1?.exerciseSession?.therapyExercise?.order - esou2?.exerciseSession?.therapyExercise?.order;
            });
            for (const exerciseSessionOfUser of therapySession.exerciseSessionsOfUser) {
                const trainingExercise = new TrainingExercise();
                trainingExercise.title = exerciseSessionOfUser.exerciseSession.exercise.title;
                trainingExercise.id = exerciseSessionOfUser.exerciseSession.exercise.id;
                trainingExercise.exerciseSessionId = exerciseSessionOfUser.exerciseSession.id;
                trainingExercise.state = exerciseSessionOfUser.exerciseSession.exerciseSessionState;
                trainingExercise.order = exerciseSessionOfUser.exerciseSession.therapyExercise.order;
                await this.setExerciseContent(
                    exerciseSessionOfUser.exerciseSession.exercise.contents.filter(
                        (i) =>
                            i?.jsonData !== null && i?.jsonData?.contentMetaDataType !== ContentMetaDataType.THUMBNAIL,
                    ),
                    trainingExercise,
                );
                this.setExerciseGoal(exerciseSessionOfUser.exerciseGoal as PhysicalExerciseGoalDto, trainingExercise);
                this.trainingDefinition.exercises.push(trainingExercise);
                if (!this.activeTrainingExercise) {
                    if (this.isExerciseSessionPlanned(trainingExercise.state)) {
                        this.activeTrainingExercise = trainingExercise;
                    }
                }
            }
        } else if (this.trainingUsingTyp === TrainingUsingTyp.CAREGIVER) {
            const exerciseSession = await this.exerciseSessionsService.getExerciseSession(this.objectId);
            this.trainingDefinition.title = exerciseSession.title;
            this.title = exerciseSession.title;
            this.trainingDefinition.exercises = [];
            this.trainingDefinition.tags = [];
            const index = 0;
            for (const childExerciseSession of exerciseSession.childExerciseSessions) {
                const exercise = await this.exerciseService.getExerciseById(childExerciseSession.exercise_id);
                const autoTrainingExercise = new TrainingExercise();
                autoTrainingExercise.title = childExerciseSession.title;
                autoTrainingExercise.id = childExerciseSession.exercise_id;
                autoTrainingExercise.exerciseSessionId = childExerciseSession.id;
                autoTrainingExercise.state = childExerciseSession.exerciseSessionState;
                autoTrainingExercise.order = index;
                await this.setExerciseContent(
                    exercise.contents.filter((i) => i.jsonData.contentMetaDataType !== ContentMetaDataType.THUMBNAIL),
                    autoTrainingExercise,
                );
                this.setExerciseGoal(
                    exercise.exerciseGoals.filter((i) => i.active)[0] as PhysicalExerciseGoalDto,
                    autoTrainingExercise,
                );
                this.trainingDefinition.exercises.push(autoTrainingExercise);
                if (!this.activeTrainingExercise) {
                    if (this.isExerciseSessionPlanned(autoTrainingExercise.state)) {
                        this.activeTrainingExercise = autoTrainingExercise;
                    }
                }
            }
        } else if (this.trainingUsingTyp === TrainingUsingTyp.CONCEPT) {
            const exercise = await this.exerciseService.getExerciseById(this.objectId);
            this.trainingDefinition.title = exercise.title;
            this.title = exercise.title;
            this.trainingDefinition.exercises = [];
            this.trainingDefinition.tags = [];
            const index = 0;
            for (const childExercise of exercise.childExercises) {
                const exercise = await this.exerciseService.getExerciseById(childExercise.childId);
                const autoTrainingExercise = new TrainingExercise();
                autoTrainingExercise.title = childExercise.childExercise.title;
                autoTrainingExercise.id = childExercise.childId;
                autoTrainingExercise.order = index;
                await this.setExerciseContent(
                    exercise.contents.filter((i) => i.jsonData.contentMetaDataType !== ContentMetaDataType.THUMBNAIL),
                    autoTrainingExercise,
                );
                this.setExerciseGoal(
                    exercise.exerciseGoals.filter((i) => i.active)[0] as PhysicalExerciseGoalDto,
                    autoTrainingExercise,
                );
                this.trainingDefinition.exercises.push(autoTrainingExercise);
                if (!this.activeTrainingExercise) {
                    if (this.isExerciseSessionPlanned(autoTrainingExercise.state)) {
                        this.activeTrainingExercise = autoTrainingExercise;
                    }
                }
            }
        }

        this.toolbarService.setTitle(this.trainingDefinition.title);
        this.initUnfinishedExerciseNumber();
        this.loadingExercisesCompleted = true;
    }

    async swipeToFeedback(trainingExercise: TrainingExercise): Promise<void> {
        if (this.trainingUsingTyp === TrainingUsingTyp.PATIENT) {
            if (this.swipeFlag) {
                this.swipeFlag = false;
                await this.openFeedback(trainingExercise);
            }
        }
    }

    hasAnyFeedback(trainingExercise: TrainingExercise): boolean {
        return (
            !trainingExercise.exerciseGoal.intensityUnit &&
            !trainingExercise.exerciseGoal.durationUnit &&
            trainingExercise.exerciseGoal.stressUnit === StressUnit.NONE &&
            trainingExercise.exerciseGoal.painUnit === PainUnit.NONE &&
            trainingExercise.exerciseGoal.feedbackCommentUnit === FeedbackCommentUnit.NONE
        );
    }

    async openFeedback(trainingExercise: TrainingExercise): Promise<void> {
        await this.userExerciseSessionsService.postUsersExerciseSessionActivate(
            this.loggedInUser.username,
            trainingExercise.exerciseSessionId,
        );
        if (this.hasAnyFeedback(trainingExercise)) {
            await this.userExerciseSessionsService.postUsersExerciseSessionFinish(
                this.loggedInUser.username,
                trainingExercise.exerciseSessionId,
            );
            const newExerciseSession = await this.userExerciseSessionsService.getExerciseSessionOfUser(
                this.loggedInUser.username,
                trainingExercise.exerciseSessionId,
            );
            trainingExercise.state = newExerciseSession.exerciseSession.exerciseSessionState;
            await this.changeToNextExerciseGoal();
        } else {
            this.initUnfinishedExerciseNumber();
            const modal = await this.modalCtrl.create({
                component: FeedbackModalComponent,
                cssClass: 'extended-height-modal',
                componentProps: {
                    exercise: trainingExercise,
                    training: this.trainingDefinition,
                    stateOfTraining: TrainingState.AUTO_MODUS,
                    unfinishedExercises: this.waitingExercise,
                    isExerciseLeave: false,
                },
            });
            await modal.present();
            const { data } = await modal.onDidDismiss();
            if (data !== null && data) {
                if (data.feedback) {
                    await this.userExerciseSessionsService.postUsersExerciseSessionFeedback(
                        this.loggedInUser.username,
                        trainingExercise.exerciseSessionId,
                        data.feedback,
                    );
                    await this.userExerciseSessionsService.postUsersExerciseSessionFinish(
                        this.loggedInUser.username,
                        trainingExercise.exerciseSessionId,
                    );
                    this.activeTrainingExercise.state = ExerciseSessionState.FINISHED;
                    await this.changeToNextExerciseGoal();
                }
            }
            this.initUnfinishedExerciseNumber();
        }
    }

    async cancelExerciseSessionOfUser(comment: FeedbackComment): Promise<void> {
        const feedback = new FeedBackDto();
        if (comment) delete comment.deletedDate;

        feedback.feedbackComment = comment;
        await this.userExerciseSessionsService.postUsersExerciseSessionFeedback(
            this.loggedInUser.username,
            this.activeTrainingExercise.exerciseSessionId,
            feedback,
        );
        await this.userExerciseSessionsService.postExerciseSessionUserPatientCancel(
            this.loggedInUser.username,
            this.activeTrainingExercise.exerciseSessionId,
        );
        this.activeTrainingExercise.state = ExerciseSessionState.PATIENT_CANCELLED;
        await this.changeToNextExerciseGoal();
    }

    isExerciseSessionPlanned(state: ExerciseSessionState): boolean {
        return [ExerciseSessionState.PLANNED, ExerciseSessionState.ACTIVE].includes(state);
    }

    initUnfinishedExerciseNumber(): void {
        this.waitingExercise = 0;
        for (const exercise of this.trainingDefinition.exercises) {
            if (this.isExerciseSessionPlanned(exercise.state)) {
                this.waitingExercise++;
            }
        }
        this.unfinishedExercises = this.waitingExercise;
    }

    async changeToNextExerciseGoal(): Promise<TrainingExercise> {
        this.index++;
        this.activeTrainingExercise = null;
        if (this.index <= this.trainingDefinition.exercises.length - 1) {
            if (this.isExerciseSessionPlanned(this.trainingDefinition.exercises[this.index].state)) {
                return (this.activeTrainingExercise = this.trainingDefinition.exercises[this.index]);
            } else {
                await this.changeToNextExerciseGoal();
            }
        } else {
            this.waitingExercise = 0;
            for (const exercise of this.trainingDefinition.exercises) {
                if (this.isExerciseSessionPlanned(exercise.state)) {
                    this.waitingExercise++;
                }
            }
            this.unfinishedExercises = this.waitingExercise;
            if (this.waitingExercise === 0) {
                if (this.trainingUsingTyp === TrainingUsingTyp.PATIENT) {
                    this.emitNextProcessStep.emit();
                } else if (this.trainingUsingTyp === TrainingUsingTyp.CAREGIVER) {
                    await this.exerciseSessionsService.postExerciseSessionFinish(this.objectId);
                    this.emitNextProcessStep.emit();
                }
            } else {
                this.index = -1;
                await this.changeToNextExerciseGoal();
            }
        }
        this.initUnfinishedExerciseNumber();
    }

    async openOtherActionPopOver(): Promise<void> {
        this.initUnfinishedExerciseNumber();
        const actionItems: ActionMenuItem[] = [];
        actionItems.push(new ActionButton(ActionItemType.BUTTON, 'Übung auslassen', ActionType.CANCELED));
        if (this.unfinishedExercises > 1) {
            actionItems.push(new ActionDivider());
            actionItems.push(
                new ActionButton(ActionItemType.BUTTON, 'Übung später machen', ActionType.REPORT_TO_LATER),
            );
        }
        const popover = await this.popoverController.create({
            component: CurafidaPopoverSelectionComponent,
            translucent: true,
            size: 'auto',
            componentProps: {
                actionItems: actionItems,
            },
        });
        await popover.present();
        const { data } = await popover.onDidDismiss();
        await this.setOtherAction(data as ActionType);
    }

    async setOtherAction(actionType: ActionType): Promise<void> {
        if (actionType === ActionType.CANCELED) {
            if (this.trainingUsingTyp === TrainingUsingTyp.PATIENT) {
                if (this.styleService.isMobile$) {
                    const buttons = [];
                    for (const item of this.customerComments.items) {
                        if (item.flag !== FeedbackFlag.NONE) {
                            buttons.push({
                                text: item.text,
                                role: item.uuid,
                            });
                        }
                    }
                    const actionSheet = await this.actionSheetController.create({
                        header: 'Warum möchtest du die Übung nicht machen?',
                        cssClass: 'training-action-sheet-class',
                        buttons,
                    });
                    await actionSheet.present();

                    const { role } = await actionSheet.onDidDismiss();
                    if (role && role !== 'backdrop') {
                        await this.cancelExerciseSessionOfUser(
                            this.customerComments.items.find((i) => i.uuid === role),
                        );
                    }
                } else {
                    const modalConfig = new ModalConfig();
                    modalConfig.modalTyp = ModalTyp.INFORMATION;
                    modalConfig.title = `Übung auslassen`;
                    modalConfig.description = 'Warum möchtest du die Übung nicht machen?';
                    modalConfig.selectItem = [];
                    for (const item of this.customerComments.items) {
                        if (item.flag !== FeedbackFlag.NONE) {
                            modalConfig.selectItem.push({ label: item.text, value: item.uuid });
                        }
                    }
                    modalConfig.hasSelectList = true;
                    modalConfig.buttonRight = new ButtonConfig();
                    if (this.unfinishedExercises > 1) {
                        modalConfig.buttonRight.buttonText = 'Weiter';
                    } else {
                        modalConfig.buttonRight.buttonText = 'Training abschließen';
                    }
                    modalConfig.buttonRight.buttonColor = 'primary';
                    const action = await this.modalAlertService.showModal(modalConfig);
                    if (action && action.action === 'right') {
                        await this.cancelExerciseSessionOfUser(
                            this.customerComments.items.find((i) => i.uuid === action.itemSelected),
                        );
                    }
                }
            } else if (this.trainingUsingTyp === TrainingUsingTyp.CAREGIVER) {
                await this.exerciseSessionsService.postExerciseSessionCancel(
                    this.activeTrainingExercise.exerciseSessionId,
                );
                this.activeTrainingExercise.state = ExerciseSessionState.CANCELLED;
                await this.changeToNextExerciseGoal();
            }
        }
        if (actionType === ActionType.REPORT_TO_LATER) {
            await this.changeToNextExerciseGoal();
        }
    }

    async openNextExercise(): Promise<void> {
        this.loadingExercisesCompleted = false;
        if (this.trainingUsingTyp === TrainingUsingTyp.PATIENT) await this.openFeedback(this.activeTrainingExercise);
        else if (this.trainingUsingTyp === TrainingUsingTyp.CAREGIVER) {
            await this.exerciseSessionsService.postExerciseSessionActivate(
                this.activeTrainingExercise.exerciseSessionId,
            );
            await this.exerciseSessionsService.postExerciseSessionFinish(this.activeTrainingExercise.exerciseSessionId);
            this.activeTrainingExercise.state = ExerciseSessionState.FINISHED;
            await this.changeToNextExerciseGoal();
        }
        if (this.trainingUsingTyp === TrainingUsingTyp.CONCEPT) {
            await this.changeToNextExerciseGoal();
        }
        this.loadingExercisesCompleted = true;
    }

    openExerciseFromIndex(index: number) {
        this.index = index;
        this.activeTrainingExercise = this.trainingDefinition.exercises[index];
    }

    private setExerciseGoal(exerciseGoal: PhysicalExerciseGoalDto, autoTrainingExercise: TrainingExercise): void {
        autoTrainingExercise.exerciseGoal = new PhysicalExerciseGoalDto();
        autoTrainingExercise.exerciseGoal.series = exerciseGoal.series;
        autoTrainingExercise.exerciseGoal.duration = exerciseGoal.duration;
        autoTrainingExercise.exerciseGoal.durationUnit = exerciseGoal.durationUnit;
        autoTrainingExercise.exerciseGoal.intensity = exerciseGoal.intensity;
        autoTrainingExercise.exerciseGoal.intensityUnit = exerciseGoal.intensityUnit;
        autoTrainingExercise.exerciseGoal.pulse = exerciseGoal.pulse;
        autoTrainingExercise.exerciseGoal.pulseVariance = exerciseGoal.pulseVariance;
        autoTrainingExercise.exerciseGoal.stressUnit = exerciseGoal.stressUnit;
        autoTrainingExercise.exerciseGoal.painUnit = exerciseGoal.painUnit;
        autoTrainingExercise.exerciseGoal.effortUnit = exerciseGoal.effortUnit;
        autoTrainingExercise.exerciseGoal.feedbackCommentUnit = exerciseGoal.feedbackCommentUnit;
    }

    private async setExerciseContent(contents: Content[], autoTrainingExercise: TrainingExercise) {
        autoTrainingExercise.contents = [];
        for (const content of contents) {
            const autoTrainingContent = new TrainingContent();
            autoTrainingContent.uuid = content.uuid;
            autoTrainingContent.name = content.jsonData.name;
            autoTrainingContent.order = content.jsonData.order;
            autoTrainingContent.description = content.description;
            autoTrainingContent.mimeType = content.mimeType;
            autoTrainingExercise.contents.push(autoTrainingContent);
        }
        autoTrainingExercise.contents.sort((a, b) => {
            if (a?.order < b?.order) {
                return -1;
            } else if (a?.order > b?.order) {
                return 1;
            }
            return 0;
        });
    }
}
