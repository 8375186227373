import { Component, OnInit, ViewChild } from '@angular/core';
import { CurafidaSegmentItem } from '../../../../common/entities/curafida-segment.item';
import { SegmentType } from '../../../../common/entities/segment.type';
import { ActionType, ButtonItemAdapterComponent, ItemType, TableConfig } from '../../../../table/entities/table';
import { SortBy } from '../../../../common/entities/paginated-response';
import { ExerciseType } from '../../../entities/exerciseSession';
import { StringItemAdapterComponent } from '../../../../table/components/table-adapter/string-item-adapter.component';
import { ExerciseCatalog } from '../../../entities/exercise';
import { TagsAdapterComponent } from '../../table-adapter/tags-adapter.component';
import { ExerciseOverviewComponent } from '../../exercise-overview/exercise-overview.component';

@Component({
    selector: 'lib-learning-template-overview',
    templateUrl: './learning-template-overview.component.html',
    styleUrls: ['./learning-template-overview.component.scss'],
})
export class LearningTemplateOverviewComponent implements OnInit {
    @ViewChild('exerciseOverviewComponent') exerciseOverviewComponent: ExerciseOverviewComponent;
    segmentType = SegmentType.TEMPLATE;
    SegmentType = SegmentType;
    initComponent = false;
    ExerciseType = ExerciseType;
    segmentLearningAdministrationPage: CurafidaSegmentItem<SegmentType>[] = [];
    exerciseListConfig: TableConfig<ExerciseCatalog[]> = new TableConfig();

    constructor() {
        this.exerciseListConfig.isOpenDetailEnable = true;
        this.exerciseListConfig.emptyListLabel = 'ANY_EXERCISE_LEARNING';
        this.exerciseListConfig.itemSettings = [
            {
                id: 'title',
                prop: 'title',
                header: 'Name',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '32%',
                sortBy: SortBy.TITLE,
                columnPosition: 0,
            },
            {
                id: 'description',
                prop: 'description',
                header: 'Beschreibung',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '40%',
                columnPosition: 1,
            },
            {
                id: 'tag',
                prop: 'tags',
                header: 'Tags',
                type: ItemType.ADAPTER,
                adapter: TagsAdapterComponent,
                width: '20%',
                columnPosition: 2,
            },
            {
                id: 'action_open',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'create',
                actionType: ActionType.OPEN_NEW_PAGE,
                width: '8%',
                columnPosition: 3,
            },
        ];
    }

    ngOnInit(): void {
        this.initTabs();
    }

    initTabs() {
        this.segmentLearningAdministrationPage = [
            new CurafidaSegmentItem({ name: 'Lerneinheitvorlagen', value: SegmentType.EXERCISE }),
            new CurafidaSegmentItem({ name: 'Lernplanvorlagen', value: SegmentType.TEMPLATE }),
        ];
    }

    async setSegmentType(event: SegmentType) {
        this.segmentType = event;
    }
}
