import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeedBack, FeedbackExerciseParams } from '../../../../entities/feedback';
import { FeedbackCommentsService } from '../../../../services/feedback-comments';
import { FeedbackComment } from '../../../../entities/feedback/feedback-comment';
import { PaginatedResponse } from '../../../../../common/entities/paginated-response';
import { ScaleItem } from '../../../../entities/scale-item';
import {
    EffortUnit,
    FeedbackCommentUnit,
    PainUnit,
    StressUnit,
} from '../../../../entities/exerciseGoal/exerciseGoalDto';
import { RangeDefinition } from '../../../../../common/components/range-selector/range-selector.component';
import { TrainingExercise } from '../../../../entities/training/training';
import { RangeType } from '../../../../entities/training/range.typ';

@Component({
    selector: 'lib-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
    @Input()
    isExerciseLeave: boolean;
    @Input()
    selectOptions;
    @Input()
    exercise: TrainingExercise;
    @Input()
    mandatoryVerbalFeedback: boolean;
    @Output()
    emitExerciseFeedback = new EventEmitter<FeedBack>();

    series = 0;
    sides = '1';
    tempBorg: number;
    feedBack: FeedBack = new FeedBack();
    customerComments: PaginatedResponse<FeedbackComment[]>;

    seriesFeedbackDefinitions: SeriesFeedbackDefinition[];
    borgItemList: ScaleItem[] = [
        { value: 0, label: 'Überhaupt nicht', color: '#FFFF00' },
        { value: 1, label: 'Sehr leicht', color: '#FFFF00' },
        { value: 2, label: 'Leicht', color: '#E1F664' },
        { value: 3, label: 'Mässig', color: '#badd6d' },
        { value: 4, label: 'Ziemlich schwer', color: '#80D755' },
        { value: 5, label: 'Schwer', color: '#14CA2A' },
        { value: 6, label: 'Schwer', color: '#78BC7B' },
        { value: 7, label: 'Sehr schwer', color: '#CC9900' },
        { value: 8, label: 'Sehr schwer', color: '#DD7F65' },
        { value: 9, label: 'Sehr schwer', color: '#EE5F54' },
        { value: 10, label: 'Sehr, sehr schwer', color: '#FF2D1D' },
    ];
    painNumberItemList: ScaleItem[] = [
        { value: 0, label: 'kein Schmerz', color: '#14CA2A' },
        { value: 1, label: '', color: '#80D755' },
        { value: 2, label: 'leicht', color: '#badd6d' },
        { value: 3, label: '', color: '#E1F664' },
        { value: 4, label: 'mittel', color: '#ddd26d' },
        { value: 5, label: '', color: '#CC9900' },
        { value: 6, label: 'stark', color: '#ec9883' },
        { value: 7, label: '', color: '#DD7F65' },
        { value: 8, label: 'sehr stark', color: '#DD7F65' },
        { value: 9, label: '', color: '#EE5F54' },
        { value: 10, label: 'maximaler Schmerz', color: '#FF2D1D' },
    ];
    EffortUnit = EffortUnit;
    PainUnit = PainUnit;
    StressUnit = StressUnit;
    FeedbackCommentUnit = FeedbackCommentUnit;
    rangeToShow = 0;

    constructor(private feedbackCommentsService: FeedbackCommentsService) {}

    async ngOnInit(): Promise<void> {
        this.rangeToShow = 0;
        this.feedbackCommentsService.getFeedbackComments(0, 30).subscribe((customerComments) => {
            this.customerComments = customerComments;
            let i = 0;
            this.seriesFeedbackDefinitions = [];
            this.feedBack.exerciseParams = [];
            while (i < this.exercise.exerciseGoal.series) {
                const seriesFeedbackDefinition = new SeriesFeedbackDefinition();
                if (this.exercise.exerciseGoal.durationUnit) {
                    seriesFeedbackDefinition.duration = new RangeDefinition();
                    seriesFeedbackDefinition.duration.type = RangeType.DURATION;
                    seriesFeedbackDefinition.order = i;
                    seriesFeedbackDefinition.duration.disabled = !this.exercise.exerciseGoal.durationUnit;
                    if (!seriesFeedbackDefinition.duration.disabled) {
                        seriesFeedbackDefinition.duration.value = this.exercise.exerciseGoal.duration;
                        seriesFeedbackDefinition.duration.min = 0;
                        seriesFeedbackDefinition.duration.max = 5000;
                    }
                }
                if (this.exercise.exerciseGoal.intensityUnit) {
                    seriesFeedbackDefinition.intensity = new RangeDefinition();
                    seriesFeedbackDefinition.intensity.type = RangeType.INTENSITY;
                    seriesFeedbackDefinition.intensity.disabled = !this.exercise.exerciseGoal.intensityUnit;
                    if (!seriesFeedbackDefinition.intensity.disabled) {
                        seriesFeedbackDefinition.intensity.value = this.exercise.exerciseGoal.intensity;
                        seriesFeedbackDefinition.intensity.min = 0;
                        seriesFeedbackDefinition.intensity.max = 500;
                    }
                }
                if (this.exercise.exerciseGoal.stressUnit === StressUnit.PULSE) {
                    seriesFeedbackDefinition.pulse = new RangeDefinition();
                    seriesFeedbackDefinition.pulse.disabled = false;
                    seriesFeedbackDefinition.pulse.type = RangeType.PULSE;
                    if (!seriesFeedbackDefinition.pulse.disabled) {
                        seriesFeedbackDefinition.pulse.value = this.exercise.exerciseGoal.pulse
                            ? this.exercise.exerciseGoal.pulse
                            : 0;
                        seriesFeedbackDefinition.pulse.min = 35;
                        seriesFeedbackDefinition.pulse.max = 230;
                    }
                }
                this.seriesFeedbackDefinitions.push(seriesFeedbackDefinition);
                this.feedBack.exerciseParams.push(
                    new FeedbackExerciseParams(
                        i,
                        this.exercise.exerciseGoal.duration,
                        this.exercise.exerciseGoal.durationUnit,
                        this.exercise.exerciseGoal.intensity,
                        this.exercise.exerciseGoal.intensityUnit,
                        this.exercise.exerciseGoal.pulse,
                    ),
                );
                i++;
            }
            if (this.exercise.exerciseGoal.durationUnit) {
                this.rangeToShow++;
            }
            if (this.exercise.exerciseGoal.stressUnit === StressUnit.PULSE) {
                this.rangeToShow++;
            }
            if (this.exercise.exerciseGoal.intensityUnit) {
                this.rangeToShow++;
            }

            this.setFeedback();
        });
    }

    setTempBorgValue(borgValue: number): void {
        this.tempBorg = borgValue;
    }

    saveBorgValue(): void {
        this.feedBack.borg = this.tempBorg;
    }

    setPainValue(painValue: number): void {
        this.feedBack.pain = painValue;
        this.setFeedback();
    }

    setPulseValue(event: number, seriesFeedbackDefinition?: SeriesFeedbackDefinition): void {
        this.feedBack.exerciseParams.find((e) => e.order === seriesFeedbackDefinition.order).pulse = Number(event);
        this.setFeedback();
    }

    setFeedbackMessage(feedbackMessage: any) {
        const feedbackComment: FeedbackComment = this.customerComments.items.find(
            (item) => item.uuid === feedbackMessage.detail.value,
        );
        delete feedbackComment.deletedDate;
        this.feedBack.feedbackComment = feedbackComment;
        this.setFeedback();
    }

    setActionDurationValue(event: number, seriesFeedbackDefinition?: SeriesFeedbackDefinition) {
        this.feedBack.exerciseParams.find((e) => e.order === seriesFeedbackDefinition.order).duration = Number(event);
        this.setFeedback();
    }

    setActionIntensityValue(event: number, seriesFeedbackDefinition?: SeriesFeedbackDefinition) {
        this.feedBack.exerciseParams.find((e) => e.order === seriesFeedbackDefinition.order).intensity = Number(event);
        this.setFeedback();
    }

    feedBackButtonFill(value: number): string {
        if (value === this.tempBorg) {
            return 'solid';
        } else {
            return 'outline';
        }
    }

    setBorgValue(borgValue: number) {
        this.feedBack.borg = borgValue;
        this.setFeedback();
    }

    private setFeedback() {
        if (
            this.exercise.exerciseGoal.feedbackCommentUnit !== FeedbackCommentUnit.FEEDBACK_COMMENT &&
            !this.mandatoryVerbalFeedback
        ) {
            delete this.feedBack.feedbackComment;
        }
        this.emitExerciseFeedback.emit(this.feedBack);
    }
}

export class SeriesFeedbackDefinition {
    order: number;
    duration?: RangeDefinition;
    intensity?: RangeDefinition;
    pulse?: RangeDefinition;
}
