import { Component, Input, OnInit } from '@angular/core';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { SupervisorService } from '../../services/supervisor';
import {
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../table/entities/table';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { User, UserRoles } from '../../../auth/entities/user';
import { PageConfig } from '../../../config/entities';
import { CurafidaAuthService } from '../../../auth/services';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { FrontendUser } from '../../pages/user-management/user-base-list';
import { RoutingSegment } from '../../../common/entities/routing-segment';
import { Router } from '@angular/router';
import { ModalConfig } from '../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../common/entities/modal/modal-button';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { ModalAlertService } from '../../../common/services/modal';
import { StyleService } from '../../../common/services/style/style.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'supervised-patient-list',
    templateUrl: './supervised-patient-list.component.html',
    styleUrls: ['./supervised-patient-list.component.scss'],
})
export class SupervisedPatientListComponent implements OnInit {
    isLoading = true;
    isLoadingSuccess = false;
    @Input()
    supervisor: User;
    @Input()
    pageConfig: PageConfig;
    supervisedPatientsTableConfig: TableConfig<FrontendUser[]> = new TableConfig<FrontendUser[]>();
    offset = 0;
    limit = 10;
    searchTerm = '';
    sortOrder: SortOrder;
    sortBy: SortBy;
    loggedInUser: User;
    UserRoles = UserRoles;
    isMobile: boolean;
    protected readonly log: Logger;

    constructor(
        private loggingService: LoggingService,
        private supervisorService: SupervisorService,
        private toastService: ToastService,
        private authService: CurafidaAuthService,
        private router: Router,
        private loadingService: LoadingService,
        private modalAlertService: ModalAlertService,
        private styleService: StyleService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit(): Promise<void> {
        this.loggedInUser = await this.authService.getSession().user;
        this.isMobile = this.styleService.isMobile$;
        this.initItemSettings();
        await this.getSupervisedPatientsList({ offset: this.offset, limit: this.limit });
    }

    async getSupervisedPatientsList(value: TableUpdateValue, searchTerm?: string): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        this.offset = value.offset;
        this.limit = value.limit;
        if (searchTerm) this.searchTerm = searchTerm;
        if (value?.sortOrder) this.sortOrder = value?.sortOrder;
        if (value?.sortBy) this.sortBy = value?.sortBy as SortBy;
        try {
            this.supervisedPatientsTableConfig.list = (await this.supervisorService.getSupervisedPatient(
                this.supervisor.username,
                {
                    offset: this.offset * this.limit,
                    limit: this.limit,
                    filter: this.searchTerm,
                    sortOrder: this.sortOrder,
                    sortBy: this.sortBy,
                    withLastActivityDate: true,
                    withTags: true,
                },
            )) as PaginatedResponse<FrontendUser[]>;
            this.supervisedPatientsTableConfig.list.items = this.setFrontendAttributes(
                this.supervisedPatientsTableConfig.list.items,
            );
            this.isLoading = false;
            this.isLoadingSuccess = true;
        } catch (e) {
            this.log.error('Error in getSupervisedPatientsList', e);
            this.isLoadingSuccess = false;
            this.isLoading = false;
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.supervisedPatientsTableConfig.list = new PaginatedResponse<FrontendUser[]>();
        }
    }

    setFrontendAttributes(patientList: FrontendUser[]): FrontendUser[] {
        for (const patient of patientList) {
            patient.fullName = `${patient.lastname}, ${patient.firstname}`;
        }
        return patientList;
    }

    async openDetailPage(username: string): Promise<void> {
        await this.router.navigate([
            RoutingSegment.MEMBER,
            RoutingSegment.PATIENT_MANAGEMENT,
            RoutingSegment.DETAIL,
            username,
        ]);
    }

    async performActionByType(event): Promise<void> {
        if (event.actionType) {
            if (event.actionType === ActionType.OPEN_NEW_PAGE) {
                await this.openDetailPage(event.item.username);
            } else if (event.actionType === ActionType.DELETE) {
                await this.removeSupervisedPatient(event.item);
                await this.getSupervisedPatientsList(
                    {
                        offset: this.offset,
                        limit: this.limit,
                        sortBy: this.sortBy,
                        sortOrder: this.sortOrder,
                    },
                    this.searchTerm,
                );
            }
        }
    }

    async removeSupervisedPatient(patient: User): Promise<void> {
        const patientName = `${patient.firstname} ${patient.lastname}`;
        const supervisorName = `${this.supervisor.firstname} ${this.supervisor.lastname}`;
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION_EXPANDING;
        modalConfig.title = `Zuordnung von Patient entfernen`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description =
            `Durch das Entfernen des Patienten wird die Zuordnung von „${patientName}“ zum Co-Betreuer „${supervisorName}“ aufgehoben.` +
            '\n\n' +
            'Sind Sie sicher, dass Sie fortfahren möchten?';

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Entfernen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                this.loadingService.startLoadingModal('Zuordnung wird entfernt.');
                await this.supervisorService.removeSupervisorOfPatient(patient.username, this.supervisor.username);
                await this.toastService.showToast('Die Zuordnung wurde erfolgreich aufgehoben.', IonicColor.success);
            } catch (e) {
                this.log.error('Error in removeSupervisedPatient', e);
                await this.toastService.showToast(
                    'Beim Entfernen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
                    IonicColor.danger,
                );
            } finally {
                this.loadingService.stopLoadingModal();
            }
        }
    }

    private initItemSettings() {
        this.supervisedPatientsTableConfig.emptyListLabel = 'USER.PATIENT.ANY_ITEM';
        this.supervisedPatientsTableConfig.itemSettings = [
            {
                id: 'nameOfUser',
                prop: 'nameOfUser',
                header: '',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '20%',
                sortBy: SortBy.LASTNAME,
                sortOrderMobile: 0,
                isMobileBold: true,
            },
            {
                id: 'lastname',
                prop: 'lastname',
                header: 'Nachname',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '25%',
                sortBy: SortBy.LASTNAME,
                columnPosition: 0,
            },
            {
                id: 'firstname',
                prop: 'firstname',
                header: 'Vorname',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '25%',
                sortBy: SortBy.FIRSTNAME,
                columnPosition: 1,
            },
            {
                id: 'birthdate',
                prop: 'birthdate',
                header: 'BIRTH_DATE',
                adapter: StringItemAdapterComponent,
                format: DateFormat.DATE,
                type: ItemType.ADAPTER,
                width: '17%',
                sortBy: SortBy.BIRTHDATE,
                columnPosition: 2,
            },
            {
                id: '',
                prop: 'gender',
                header: 'Geschlecht',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '17%',
                columnPosition: 3,
            },
            {
                id: '',
                prop: 'genderAndBirthdate',
                header: '',
                type: ItemType.ADAPTER,
                adapter: StringItemAdapterComponent,
                width: '29%',
                sortOrderMobile: 2,
            },
            {
                id: 'action_open',
                prop: 'id',
                header: '',
                color: 'danger',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'close-outline',
                actionType: ActionType.DELETE,
                width: '8%',
                columnPosition: 4,
            },
            {
                id: 'action_open',
                prop: 'id',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'open-outline',
                actionType: ActionType.OPEN_NEW_PAGE,
                width: '8%',
                columnPosition: 4,
            },
        ];
        this.supervisedPatientsTableConfig.isOpenDetailEnable = true;
    }

    protected readonly event = event;
}
