import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from '../../../../common/services/toast-service/toast-service.service';
import { ModalAlertService } from '../../../../common/services/modal';
import { ExerciseType } from '../../../entities/exerciseSession';
import { TagsService } from '../../../services/tags';
import { TagCategoryView } from '../../../entities/tag/tag-category.entity';
import { TagModalComponent } from '../../modal/tag-modal/tag-modal.component';
import { ModalController } from '@ionic/angular';
import { Tag, TagCreationDto, TagUpdateDto } from '../../../entities/tag/tag.entity';
import { ModalConfig } from '../../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../../common/entities/modal/modal-button';
import { IonicColor } from '../../../../common/entities/toast/ionic-color';
import { TranslateService } from '@ngx-translate/core';
import { Logger, LoggingService } from '../../../../logging/logging.service';
import { CommonComponentsModule } from '../../../../common/components/common-components.module';

@Component({
    selector: 'lib-tags-list',
    templateUrl: './exercise-tag.component.html',
    styleUrls: ['./exercise-tag.component.scss'],
    standalone: true,
    imports: [CommonComponentsModule],
})
export class ExerciseTagComponent implements OnInit {
    tagCategoryViews: TagCategoryView[];
    @Input()
    exerciseType: ExerciseType = ExerciseType.TRAINING;
    selectedTagCategory: TagCategoryView;
    protected readonly log: Logger;

    constructor(
        private modalAlertService: ModalAlertService,
        private toastService: ToastService,
        private tagsService: TagsService,
        private modalController: ModalController,
        private translate: TranslateService,
        private loggingService: LoggingService,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
    }

    async ngOnInit() {
        await this.getTagList();
        this.selectedTagCategory = this.tagCategoryViews[0];
    }

    async addNewTagCategory() {
        const modal = await this.modalController.create({
            component: TagModalComponent,
            cssClass: 'modal-tags-css',
            componentProps: {
                title: 'TAG.CATEGORY.CREATE',
                tag: new Tag(),
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data !== null && data) {
            try {
                data.tag.exerciseType = this.exerciseType;
                const newTagCategoy = await this.tagsService.createTagCategories(data.tag);
                await this.getTagList();
                this.selectedTagCategory = this.tagCategoryViews.find((i) => i.uuid === newTagCategoy.uuid);
                await this.toastService.showToast('TAG.CATEGORY.SUCCESS.CREATE', IonicColor.success);
            } catch (err) {
                this.log.error('Error in addNewTagCategorie', err);
                if (err.error.message === 'This tag category already exists.') {
                    await this.toastService.showToast(
                        'Diese Kategorie existiert bereits. Bitte anderen Namen wählen',
                        IonicColor.danger,
                    );
                } else if (err.error.message === 'label must be longer than or equal to 1 characters') {
                    await this.toastService.showToast(
                        'Name der Kategorie muss mindestens ein Zeichen lang sein. Bitte längeren Namen eingeben.',
                        IonicColor.danger,
                    );
                } else {
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
                await this.getTagList();
            }
        }
    }

    async getTagList() {
        this.tagCategoryViews = (await this.tagsService.getTagCategories(this.exerciseType)) as TagCategoryView[];
        this.tagCategoryViews = this.tagCategoryViews.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
        for (const tag of this.tagCategoryViews) {
            tag.areSubLinesHidden = true;
            tag.tags.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
        }
    }

    async addNewTag(tagCategory: TagCategoryView) {
        const modal = await this.modalController.create({
            component: TagModalComponent,
            cssClass: 'modal-tags-css',
            componentProps: {
                title: 'TAG.CREATE',
                tag: new Tag(),
            },
        });

        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data !== null && data) {
            try {
                const dto = new TagCreationDto(data.tag.label, this.exerciseType, tagCategory.uuid);
                const newTag = await this.tagsService.createTag(dto);
                tagCategory.tags.push(newTag);
                delete tagCategory.areSubLinesHidden;
                delete tagCategory.isDeleteIconDisabled;
                delete tagCategory.iconName;
                await this.tagsService.updateTagCategories(tagCategory);
                this.translate.get('TAG.SUCCESS.CREATE', { label: newTag.label }).subscribe((value) => {
                    this.toastService.showToast(value, IonicColor.success);
                });
            } catch (err) {
                this.log.error('Error in addNewTag', err);
                if (err.error.message === 'This tag already exists.') {
                    await this.toastService.showToast('TAG.ERROR.ALREADY_EXISTS', IonicColor.danger);
                } else if (err.error.message === 'label must be longer than or equal to 1 characters') {
                    await this.toastService.showToast('TAG.ERROR.TOO_SHORT', IonicColor.danger);
                } else {
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
                await this.getTagList();
            }
        }
    }

    async deleteTag(tag: Tag, tagCategory: TagCategoryView) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        this.translate.get('DELETE_TAG', { label: tag.label }).subscribe((value) => (modalConfig.title = value));
        modalConfig.titleIcon = 'warning-outline';

        if ([ExerciseType.TRAINING, ExerciseType.COURSE, ExerciseType.TASK].includes(this.exerciseType)) {
            this.translate
                .get(`TAG_${this.exerciseType?.toUpperCase()}_IS_DELETE`, { label: tag.label })
                .subscribe((value) => (modalConfig.description = value));
        } else {
            this.log.error(`Translation for exerciseType missing: ${this.exerciseType}`);
        }

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'DELETE';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                let tagWasDeleteString = 'TAG_WAS_DELETED';
                if ([ExerciseType.TRAINING, ExerciseType.COURSE, ExerciseType.TASK].includes(this.exerciseType)) {
                    tagWasDeleteString = `TAG_${this.exerciseType?.toUpperCase()}_WAS_DELETE`;
                }
                await this.tagsService.deleteTag(tag.uuid);
                tagCategory.tags = tagCategory.tags.filter((item) => item.uuid !== tag.uuid);
                this.translate.get(tagWasDeleteString, { label: tag.label }).subscribe((value) => {
                    this.toastService.showToast(value, IonicColor.success);
                });
            } catch (err) {
                this.log.error('Error in deleteTag', err);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }

    async updateTag(tag: Tag) {
        const labelBefore = tag.label;
        const modal = await this.modalController.create({
            component: TagModalComponent,
            cssClass: 'modal-tags-css',
            componentProps: {
                title: 'TAG.UPDATE',
                tag: tag,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data !== null && data) {
            try {
                await this.tagsService.updateTag(
                    new TagUpdateDto(data.tag.uuid, data.tag.label, data.tag.exerciseType),
                );
            } catch (err) {
                tag.label = labelBefore;
                this.log.error('Error in updateTag', err);
                if (err.error.message === 'This tag already exists.') {
                    await this.toastService.showToast(
                        'Dieser Name ist bereits vergeben. Bitte anderen Namen wählen.',
                        IonicColor.danger,
                    );
                } else {
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
                await this.getTagList();
            }
        }
    }

    async updateCategoryTag(tagCategory: TagCategoryView) {
        const modal = await this.modalController.create({
            component: TagModalComponent,
            cssClass: 'modal-tags-css',
            componentProps: {
                title: 'TAG.CATEGORY.UPDATE',
                tag: tagCategory,
            },
        });
        await modal.present();
        const { data } = await modal.onDidDismiss();
        if (data !== null && data) {
            try {
                delete data.tag.areSubLinesHidden;
                delete data.tag.isDeleteIconDisabled;
                delete data.tag.iconName;
                await this.tagsService.updateTagCategories(data.tag);
            } catch (err) {
                this.log.error('Error in updateCategoryTag', err);
                if (err.error.message === 'This tag category already exists.') {
                    await this.toastService.showToast('Diese Kategorie existiert schon.', IonicColor.danger);
                } else {
                    await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
                }
                await this.getTagList();
            }
        }
    }

    async deleteTagCategory(tagCategory: TagCategoryView) {
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = this.translate.instant('DELETE_TAG', { label: tagCategory.label });
        modalConfig.description = this.translate.instant('TAG.CATEGORY.PROMPT.DELETE', { label: tagCategory.label });

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'DELETE';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                await this.tagsService.deleteTagCategories(tagCategory.uuid);
                this.tagCategoryViews = this.tagCategoryViews.filter((item) => item.uuid !== tagCategory.uuid);
                this.selectedTagCategory = this.tagCategoryViews.length > 0 ? this.tagCategoryViews[0] : null;
                this.translate.get('TAG.CATEGORY.SUCCESS.DELETE', { label: tagCategory.label }).subscribe((value) => {
                    this.toastService.showToast(value, IonicColor.success);
                });
            } catch (err) {
                this.log.error('Error in deleteTagCategory', err);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            }
        }
    }

    segmentChanged(tag: TagCategoryView) {
        this.selectedTagCategory = tag;
    }

    setDeleteButton(tag: TagCategoryView, action: string) {
        if (action === 'out') {
            tag.isDeleteIconDisabled = true;
            tag.iconName = '';
        }
        if (action === 'over') {
            tag.isDeleteIconDisabled = false;
            tag.iconName = 'trash-outline';
        }
    }
}
