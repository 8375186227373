import { AccountType, User, UserRoles } from '../../../auth/entities/user';
import {
    ActionType,
    ButtonItemAdapterComponent,
    DateFormat,
    ItemType,
    TableConfig,
    TableUpdateValue,
} from '../../../table/entities/table';
import { PaginatedResponse, SortBy, SortOrder } from '../../../common/entities/paginated-response';
import { ToastService } from '../../../common/services/toast-service/toast-service.service';
import { IonicColor } from '../../../common/entities/toast/ionic-color';
import { UsersService } from '../../services/user';
import { CurafidaAuthService } from '../../../auth/services';
import { SupervisorService } from '../../services/supervisor';
import { ConfigService } from '../../../config/services';
import { Logger, LoggingService } from '../../../logging/logging.service';
import { ModalConfig } from '../../../common/entities/modal/modal-config';
import { ModalTyp } from '../../../common/entities/modal/modal-typ';
import { ButtonConfig } from '../../../common/entities/modal/modal-button';
import { LoadingService } from '../../../common/services/loading/loading.service';
import { ModalAlertService } from '../../../common/services/modal';
import { StringItemAdapterComponent } from '../../../table/components/table-adapter/string-item-adapter.component';
import { UserTag } from '../../entities/user-tag/user-tag.entity';
import { CaregiverService } from '../../services/caregiver/caregiver.service';
import { ActionMenuItem } from '../../../table/entities/action-menu.item';
import { FullNamePipe } from '../../full-name.pipe';
import { SegmentType } from '../../../common/entities/segment.type';
import { TagsAdapterComponent } from '../../../therapy/components/table-adapter/tags-adapter.component';
import { Subscription } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { UserSearchParams } from '../../entities/user-search-params';

interface getPatientsArgs {
    value: TableUpdateValue;
    searchTerm: string;
    status?: SegmentType;
    tags?: UserTag[];
}

export class UserBaseList {
    isLoading = true;
    isLoadingSuccess = false;
    patientOffset: number;
    limit = 10;
    patientSortBy: SortBy = SortBy.LASTNAME;
    patientSortOrder: SortOrder;
    patientSearchTerm: string;
    patientsListConfig: TableConfig<FrontendUser[]> = new TableConfig<FrontendUser[]>();
    loggedInUser: User;
    UserRoles = UserRoles;
    subscriptions: Subscription[];
    newSearchParams = new EventEmitter<UserSearchParams>();
    protected readonly log: Logger;

    constructor(
        public usersService: UsersService,
        public toastService: ToastService,
        public authService: CurafidaAuthService,
        public supervisorService: SupervisorService,
        public configService: ConfigService,
        public loggingService: LoggingService,
        public loadingService: LoadingService,
        public modalAlertService: ModalAlertService,
        public caregiverService: CaregiverService,
        readonly fullNamePipe: FullNamePipe,
    ) {
        this.log = this.loggingService.getLogger(this.constructor.name);
        this.loggedInUser = this.authService.getSession().user;
    }

    async getAllPatientList({ value, searchTerm, status, tags }: getPatientsArgs): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        this.patientOffset = value.offset;
        if (value.limit) this.limit = value.limit;
        if (value.sortBy) this.patientSortBy = value?.sortBy as SortBy;
        if (value.sortOrder) this.patientSortOrder = value?.sortOrder;
        if (searchTerm) this.patientSearchTerm = searchTerm;
        let tagUuids: string[];
        if (tags) {
            if (tags.length === 0) {
                tagUuids = [];
            } else {
                tagUuids = tags?.map((i) => i.uuid);
            }
        }
        try {
            this.patientsListConfig.list = (await this.usersService.getUsers({
                offset: this.patientOffset * this.limit,
                limit: this.limit,
                roles: [UserRoles.PATIENT],
                filter: this.patientSearchTerm,
                sortOrder: this.patientSortOrder,
                sortBy: this.patientSortBy,
                tagUuids: tagUuids,
                withTags: true,
                withLastActivityDate: true,
                ...this.getPatientListStatusArgs(status),
            })) as PaginatedResponse<FrontendUser[]>;
            this.patientsListConfig.list.items.forEach((it) => (it.fullName = this.fullNamePipe.transform(it, true)));
            this.isLoadingSuccess = true;
        } catch (e) {
            this.isLoadingSuccess = false;
            this.log.error('Error in getPatientList', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.patientsListConfig.list = new PaginatedResponse<FrontendUser[]>();
        } finally {
            this.isLoading = false;
        }
    }

    async getSupervisedPatientList(
        value: TableUpdateValue,
        searchTerm: string,
        status: SegmentType,
        tags?: UserTag[],
    ): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;
        this.patientOffset = value.offset;
        if (value.limit) this.limit = value.limit;
        if (searchTerm) this.patientSearchTerm = searchTerm;
        let tagUuids: string[];
        if (tags) {
            if (tags.length === 0) {
                tagUuids = [];
            } else {
                tagUuids = tags?.map((i) => i.uuid);
            }
        }
        try {
            this.patientsListConfig.list = (await this.supervisorService.getSupervisedPatient(
                this.loggedInUser.username,
                {
                    offset: this.patientOffset * this.limit,
                    limit: this.limit,
                    filter: this.patientSearchTerm,
                    sortOrder: this.patientSortOrder,
                    sortBy: this.patientSortBy,
                    tagUuids: tagUuids,
                    withTags: true,
                    withLastActivityDate: true,
                    ...this.getPatientListStatusArgs(status),
                },
            )) as PaginatedResponse<FrontendUser[]>;
            this.patientsListConfig.list.items.forEach((it) => (it.fullName = this.fullNamePipe.transform(it, true)));
            this.isLoadingSuccess = true;
        } catch (e) {
            this.isLoadingSuccess = false;
            this.log.error('Error in getPatientList', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.patientsListConfig.list = new PaginatedResponse<FrontendUser[]>();
        } finally {
            this.isLoading = false;
        }
    }

    async getCarePatientList(
        value: TableUpdateValue,
        searchTerm: string,
        status: SegmentType,
        tags?: UserTag[],
    ): Promise<void> {
        this.isLoading = true;
        this.isLoadingSuccess = false;

        this.patientOffset = value.offset;
        if (value.limit) this.limit = value.limit;
        if (searchTerm) this.patientSearchTerm = searchTerm;
        try {
            this.patientsListConfig.list = (await this.caregiverService.fetchCaredPatients(this.loggedInUser.username, {
                offset: this.patientOffset * this.limit,
                limit: this.limit,
                filter: this.patientSearchTerm,
                sortOrder: this.patientSortOrder,
                sortBy: this.patientSortBy,
                tagUuids: tags?.map((i) => i.uuid),
                withTags: true,
                withLastActivityDate: true,
                ...this.getPatientListStatusArgs(status),
            })) as PaginatedResponse<FrontendUser[]>;
            this.patientsListConfig.list.items.forEach((it) => (it.fullName = this.fullNamePipe.transform(it, true)));
            this.isLoadingSuccess = true;
        } catch (e) {
            this.isLoadingSuccess = false;
            this.log.error('Error in getPatientList', e);
            await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            this.patientsListConfig.list = new PaginatedResponse<FrontendUser[]>();
        } finally {
            this.isLoading = false;
        }
    }

    initPatientTableConfig(): void {
        const lastnameTableItem = {
            id: 'lastname',
            prop: 'lastname',
            header: 'Nachname',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            width: '20%',
            sortBy: SortBy.LASTNAME,
            sortOrderWeb: 0,
        };
        const firstnameTableItem = {
            id: 'firstname',
            prop: 'firstname',
            header: 'Vorname',
            type: ItemType.ADAPTER,
            adapter: StringItemAdapterComponent,
            width: '20%',
            sortBy: SortBy.FIRSTNAME,
            sortOrderWeb: 1,
        };
        const birthdateTableItem = {
            id: 'birthdate',
            prop: 'birthdate',
            header: 'BIRTH_DATE',
            adapter: StringItemAdapterComponent,
            format: DateFormat.DATE,
            type: ItemType.ADAPTER,
            width: '15%',
            sortBy: SortBy.BIRTHDATE,
            sortOrderWeb: 2,
        };
        const tagsTableItem = {
            id: 'tags',
            prop: 'tags',
            header: 'TAG.PLURAL',
            adapter: TagsAdapterComponent,
            type: ItemType.ADAPTER,
            width: '21%',
            sortOrderWeb: 3,
        };

        this.patientsListConfig.emptyListLabel = 'USER.PATIENT.ANY_ITEM';
        this.patientsListConfig.itemSettings = [
            lastnameTableItem,
            firstnameTableItem,
            birthdateTableItem,
            tagsTableItem,
        ];

        if (this.configService.config.isUsingMyMedax && !this.loggedInUser.roles.includes(UserRoles.manage_user)) {
            this.patientsListConfig.itemSettings.push({
                id: 'modal_open',
                prop: '',
                header: '',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'list-circle-outline',
                actionType: ActionType.MODAL,
                width: '8%',
                columnPosition: 4,
            });
        } else {
            this.patientsListConfig.itemSettings.find((i) => i.prop === 'tags').width = '29%';
        }
        if (this.loggedInUser.roles.includes(UserRoles.delete_patient)) {
            this.patientsListConfig.itemSettings.push({
                id: 'action_delete',
                prop: 'id',
                header: '',
                color: 'danger',
                type: ItemType.ADAPTER,
                adapter: ButtonItemAdapterComponent,
                icon: 'trash-outline',
                actionType: ActionType.DELETE,
                width: '8%',
                columnPosition: 4,
            });
        }

        this.patientsListConfig.itemSettings.push({
            id: 'action_open',
            prop: 'id',
            header: '',
            type: ItemType.ADAPTER,
            adapter: ButtonItemAdapterComponent,
            icon: 'open-outline',
            actionType: ActionType.OPEN_NEW_PAGE,
            width: '8%',
            columnPosition: 4,
        });
        this.patientsListConfig.isOpenDetailEnable = true;
    }

    async deleteCurrentUser(user: User, role: UserRoles | string): Promise<void> {
        const userName = `${user.firstname}  ${user.lastname}`;
        const modalConfig = new ModalConfig();
        modalConfig.modalTyp = ModalTyp.INFORMATION;
        modalConfig.title = `${userName} löschen`;
        modalConfig.titleIcon = 'warning-outline';
        modalConfig.description = `Die Daten werden unwiderruflich gelöscht.
        Wenn Sie den Benutzer „${userName}“ löschen möchten, klicken Sie auf „Löschen“.`;

        modalConfig.buttonRight = new ButtonConfig();
        modalConfig.buttonRight.buttonText = 'Löschen';
        modalConfig.buttonRight.buttonColor = 'danger';
        const action = await this.modalAlertService.showModal(modalConfig);
        if (action && action.action === 'right') {
            try {
                this.loadingService.startLoadingModal('Daten werden gelöscht.');
                if (role === UserRoles.PATIENT) {
                    await this.usersService.deletePatient(user.username);
                } else {
                    await this.usersService.deleteUser(user.username);
                }
                await this.toastService.showToast('Das Benutzerkonto wurde erfolgreich gelöscht.', IonicColor.success);
            } catch (err) {
                this.log.error('Error in deleteCurrentUser', err);
                await this.toastService.showToast(ToastService.errorMessage, IonicColor.danger);
            } finally {
                this.loadingService.stopLoadingModal();
            }
        }
    }
    getPatientListStatusArgs(status: SegmentType): Partial<UserSearchParams> {
        const sharedParams = { roles: [UserRoles.PATIENT] };
        switch (status) {
            case SegmentType.ALL:
                return { ...sharedParams };
            case SegmentType.PASSIVE:
                return { ...sharedParams, disabled: false, accountType: AccountType.PLAIN_USER };
            case SegmentType.REGISTERED:
                return {
                    ...sharedParams,
                    disabled: false,
                    accountType: AccountType.LOGIN_USER,
                    emailVerified: false,
                };
            case SegmentType.ACTIVE:
                return { ...sharedParams, disabled: false, inactive: false, emailVerified: true };
            case SegmentType.INACTIVE:
                return { ...sharedParams, disabled: false, inactive: true, emailVerified: true };
            // TODO: Trainings tauchen nicht im Journal-View auf
            case SegmentType.HAS_THERAPY:
                return { ...sharedParams, disabled: false, inactive: false, hasTherapy: true };
            case SegmentType.FINISHED:
                return { ...sharedParams, disabled: false, finished: true };
            case SegmentType.DISABLED:
                return { ...sharedParams, disabled: true };
            default:
                return { ...sharedParams };
        }
    }
}

export class FrontendUser extends User {
    actions: ActionMenuItem[];
}
